import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import ThirdStep from "./components/ThirdStep";
import {DefaultLayout} from "./components/DefaultLayout";
import MainFrame from "./components/MainFrame";
import { Provider } from 'react-redux'
import {createAppStore} from "./store";
import {ScanGateway} from "./gateways/Scan.gateway";

const scanGateway = new ScanGateway()

const dependencies = {
    scanGateway,
}
export const store = createAppStore(dependencies)

const router = createBrowserRouter([
    {
        path: "/",
        element: <Outlet/>,
        children: [
            {
                path: '/',
                element: <DefaultLayout/>,
                children: [
                    {
                        path: '/',
                        element: <MainFrame/>
                    },
                    {
                        path: '/results',
                        element: <ThirdStep />,
                    }
                ]
            }
        ]
    },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <RouterProvider router={router} />
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
