import {useSelector} from "react-redux";
import {selectAuditData} from "../../../store/stepsSlice";

export function useBrowsingHistoryHook() {
    const auditData = useSelector(selectAuditData)

    const browsing_history = auditData?.data?.browsing_history

    return {browsing_history};
}
