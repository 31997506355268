import React from "react";
import { useHeaderTitleHook } from "./use-headerTitle.hook";
import BrowsingHistory from "../Annex/BrowsingHistory";
import { ArrowPathIcon, PencilIcon } from "@heroicons/react/16/solid";
import { useThirdStepHook } from "../../use-thirdStep.hook";
import SubsectionTitle from "../SectionSubComp/SubsectionTitle";
import Paragraph from "../SectionSubComp/Paragraph";
import ResultsAnnouncement from "../SectionSubComp/ResultsAnnouncement";

function HeaderTitle() {
    const { reloadAudit } = useThirdStepHook()

    const { start_time, uri_ins } = useHeaderTitleHook();

    return <div id="info" className="bg-white pb-4 mt-4 pt-10 px-8 rounded-xl mb-2">
        <div className='flex flex-row w-12/12'>
            <PencilIcon className='max-h-[2em] my-auto mr-3' />
            <h1 className="text-blackText text-3xl font-semibold pt-1">Informations générales sur l'audit du site <span className="text-greenClara">{uri_ins}</span></h1>
        </div>
        <div className="flex flex-row justify-between">
            <div className=" my-5 w-2/3 pr-8">
                <SubsectionTitle value="Comment lire ce rapport ?"></SubsectionTitle>
                <Paragraph value={<span>Le procédé de l'audit consiste à visiter le site accessible à cette adresse, tel un internaute, au moyen d'un navigateur vierge.
                    <br />
                    Ce rapport vous informe sur la présence d'élément à risques relatif à votre site.
                    <br />
                    Les points d'analyse sont relatifs aux bonnes pratiques en matière de conformité RGPD et de sécurité liée au chiffrement.
                    <br />
                    Les scores obtenus sont élevés s'il existe peu de zones de risque de non conformité détectée par ce scanner. À l'inverse les scores sont faibles si des zones de risques ont été identifiées.
                    Vous pouvez accéder aux solutions et interprétations des résultats directement en cliquant sur les boutons "Solution" de chaque section de ce rapport.
                    <br />De plus, si vous consitatez l'existence de risque, cela signifie qu'il vous faut investiguer sur ce sujets. La section solution vous indique comment procéder à ces investigations.
                    Ce rapport n'est pas conservé et nécessite d'être conservé par vos soins.</span>} />
                <BrowsingHistory />
            </div>
            <div className="mt-4 pl-2 w-1/3">
                <SubsectionTitle value="Mettre à jour l'audit" />
                <Paragraph value={<span>Ce rapport d'audit a été généré le <span className="font-semibold p-2 border rounded-lg border-borderGrey bg-gback">{new Date(start_time).toLocaleString()}</span>.</span>} />                <button
                    type="button"
                    className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-greenClara px-3 py-2 text-md font-['Figtree'] font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-greenClara"
                    onClick={reloadAudit}
                >
                    <ArrowPathIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Lancer la mise à jour du rapport
                </button>
                <ResultsAnnouncement>Le scanner ne navigue par défaut que sur la page dont vous avez renseignée l'adresse URL.
                    Si vous souhaitez procéder à un audit de toutes ses pages, vous pouvez nous contacter
                    pour <span className="font-semibold text-blackText">disposer d'un accès à une version plus évoluer de ce scanner avec une démo individuelle</span>.
                </ResultsAnnouncement>
                <a

                    className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-greenClara px-3 py-2 text-lg font-['Figtree'] font-semibold text-white hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-greenClara"
                    href="https://olympe.legal"
                >Demander une démo</a>

            </div>
        </div>




    </div>
}

export default HeaderTitle;
