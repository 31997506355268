import { TagIcon } from '@heroicons/react/16/solid'
import React from 'react'

export default function SectionTitle({ value, destination }) {
    return (
        <div className='flex flex-row justify-between'>
            <div className='flex flex-row w-11/12'>
                <TagIcon className='max-h-[2em] my-auto mr-3' />
                <h1 className="text-blackText text-3xl font-semibold pt-1">{value}</h1>
            </div>
            <div className='flex'>
                <a href={`#solution-${destination}`} className="bg-greenClara h-fit text-white px-[12px] pt-[8px] pb-[8px] font-['Figtree'] text-xl font-semibold tracking-wide rounded-lg leading-8">Solution</a>
            </div>
        </div>

    )
}
