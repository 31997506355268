import React from 'react'
import AutomatedEvidenceCollection from "./ThirdStep/AutomatedEvidenceCollection";
import TitleBlockHeader from "./ThirdStep/TitleBlockHeader";
import OlympeFullLogo from './OlympeFullLogo';
import { useCustomeThirdStepHook } from './use-customThirdStep.hook';

export default function ThirdStep() {
    const { uriDest } = useCustomeThirdStepHook()

    return (
        <div className="flex flex-col flex-wrap mx-auto pt-20 pb-10">
            <div className='break-all w-[50vw] md:w-[70vw]'>
                <div className='w-1/5 mx-auto'>
                    <OlympeFullLogo></OlympeFullLogo>
                </div>
                <h1 className='tracking-tight text-4xl text-blackText font-semibold leading-10 mt-10 mb-4 pb-2.5 text-center'>Audit du risque RGPD du site internet {uriDest}</h1>

                <div className='pt-20'>
                    <TitleBlockHeader />
                    <AutomatedEvidenceCollection />
                </div>

            </div>
        </div>
    )
}
