import React from 'react'
import RecipientsAndTransfers from './RecipientsAndTransfers';
import DataAndRetentionPeriod from './DataAndRetentionPeriod';
import Security from './Security';
function AutomatedEvidenceCollection() {
    return (
        <div className='w-full'>
            <DataAndRetentionPeriod destination="data" />
            <RecipientsAndTransfers destination="transfers" />
            <Security destination="security" />
        </div>
    );
}

export default AutomatedEvidenceCollection;
