import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import {useSelector} from "react-redux";
import {selectCurrentStep} from "../store/stepsSlice";

export function FrameSteps() {
    const step = useSelector(selectCurrentStep)
    switch (step) {
        case 0:
            return <FirstStep />
        case 1:
            return <SecondStep />
        case 2:
            return <ThirdStep />
        default:
            return
    }

}
