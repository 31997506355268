import { useBrowsingHistoryHook } from "./use-browsingHistory.hook";
import React from "react";
import { nanoid } from "nanoid";
import SubsectionTitle from "../SectionSubComp/SubsectionTitle";
import Paragraph from "../SectionSubComp/Paragraph";

function BrowsingHistory() {
    const { browsing_history } = useBrowsingHistoryHook();
    return (
        <div>
            <SubsectionTitle value="Navigation effectuée"></SubsectionTitle>
            <Paragraph value="Les pages consultées durant l'audit sont listées ci-après et ont été visitées dans l'ordre suivant :" />
            <Paragraph value={<ol className={'pl-8 mb-4 list-decimal'}>
                {browsing_history.map((link) => (
                    <li className={'text-md leading-6 mt-1'} key={nanoid()}>
                        <a className={'text-greenClara font-semibold'} href={link}>{link}</a>
                    </li>
                ))}
            </ol>} />
            <Paragraph value={<span>Au total <span className="font-bold text-2xl">{browsing_history?.length}</span> page{browsing_history?.length > 1 ? 's' : ''} {browsing_history?.length > 1 ? 'ont' : 'a'} été visitée{browsing_history?.length > 1 ? 's' : ''} pour les besoins de l'audit.</span>} />

        </div>

    );
}

export default BrowsingHistory;
