import { FirstStepForm } from "./FirstStepForm";

export default function FirstStep() {
    return (
        <div className='text-center w-1/2'>
            <h1 className='tracking-tight text-4xl text-primary font-semibold leading-10 mt-6 mb-4 pb-2.5'>Détectez les risques RGPD d'un site internet</h1>
            <p className='mb-4 text-base leading-6'>Cette estimation est réalisée uniquement à partir d'informations publiquement disponibles.</p>
            <FirstStepForm />
        </div>
    )
}
