import {combineReducers, configureStore} from '@reduxjs/toolkit'
import stepsReducer from './stepsSlice'

export const entitiesReducer = combineReducers({
    steps: stepsReducer,
})
export const rootReducer = combineReducers({
    entities: entitiesReducer,
})


export const createAppStore = (
    dependencies,
    preloadedState,
) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: dependencies,
                },
                serializableCheck: {},
                preloadedState,
            }),
    })
}
