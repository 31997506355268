import OlympeFullLogo from './OlympeFullLogo'
import { FrameSteps } from "./FrameSteps";



export default function MainFrame() {
    return (
        <div className='w-full gap-4 flex flex-col items-center justify-center place-content-center'>
            <div className='w-1/5'>
                <OlympeFullLogo />
            </div>

            <FrameSteps />
        </div>
    )
}

