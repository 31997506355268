import { useFirstStepHook } from "./use-firstStep.hook";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export function FirstStepForm() {
    const { updateInputValue, performUrlSearch, inputValue, errorMessage } = useFirstStepHook()

    const errorStyle = () => {
        return errorMessage?.length && 'border border-red-500'
    }
    return <form action="" onSubmit={performUrlSearch}>
        <div>
            <label htmlFor="url" className="block text-sm font-medium leading-6 text-primary">
                Renseignez l'URL du site
            </label>
            <div className="mt-2 flex flex-col items-center gap-2 w-full">
                <div
                    className="w-full flex rounded-md shadow-sm ring-1 bg-white ring-inset ring-slate-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-billiard sm:max-w-md">
                    <span className="flex select-none items-center pl-3 text-slate-500 sm:text-sm">https://</span>
                    <input
                        type="text"
                        name="url"
                        id="url"
                        onChange={updateInputValue}
                        className={`${errorStyle()} block flex-1 border-0 bg-transparent py-1.5 pl-1 text-primary placeholder:text-slate-400 focus:ring-0 sm:text-sm sm:leading-6`}
                        placeholder="www.example.com"
                        value={inputValue}
                    />
                </div>
                <p className={'text-left text-red-500 text-sm'}>{errorMessage}</p>
                <button
                    type="submit"
                    className=" hover:cursor-pointer inline-flex items-center gap-x-2 rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                >
                    <MagnifyingGlassIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Rechercher
                </button>
            </div>
        </div>
    </form>

}
