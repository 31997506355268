import { useSelector } from "react-redux";
import { selectAuditData } from "../../store/stepsSlice";

function sortBySeverity(data) {
    const severityToNumber = {
        CRITICAL: 0,
        HIGH: 1,
        MEDIUM: 2,
        LOW: 3,
        WARN: 4,
        OK: 5,
        INFO: 6,
    };
    const sortSeverity = (a, b) => {
        return severityToNumber[a.severity] - severityToNumber[b.severity];
    };
    // return data.sort(sortSeverity);
    return data;
}

export function SecurityHook() {
    const auditData = useSelector(selectAuditData)

    const uri_dest = auditData?.data?.uri_dest
    const uri_ins = auditData?.data?.uri_ins
    const browsing_history = auditData?.data?.browsing_history
    const start_time = auditData?.data?.start_time
    const end_time = auditData?.data?.end_time
    const browser = auditData?.data?.browser
    const uri_refs = auditData?.data?.uri_refs
    const host = auditData?.data?.host
    const secureConnection = auditData?.data?.secure_connection
    const testSSL = auditData?.data?.testSSL
    const firstSSLScanResult = testSSL?.scanResult[0]
    const protocols = auditData?.data?.testSSL?.scanResult[0].protocols ?? []
    const sortedProtocols = sortBySeverity(protocols)
    const vulnerabilities = auditData.data.testSSL.scanResult[0].vulnerabilities ?? []
    const sortedVulnerabilitiesBySeverity = sortBySeverity(vulnerabilities)
    const unsafeForms = auditData?.data?.unsafeForms

    const vulnerabilitiesBySeverity = firstSSLScanResult
        ? firstSSLScanResult.vulnerabilities.reduce((acc, vulnerability) => {
            acc[vulnerability.severity] = (acc[vulnerability.severity] || 0) + 1;
            return acc;
        }, {})
        : {};

    let vulnerabilitiesBySeverityCritical = vulnerabilitiesBySeverity['CRITCAL'] || 0;
    let vulnerabilitiesBySeverityHigh = vulnerabilitiesBySeverity['HIGH'] || 0;
    let vulnerabilitiesBySeverityMedium = vulnerabilitiesBySeverity['MEDIUM'] || 0;
    let vulnerabilitiesBySeverityLow = vulnerabilitiesBySeverity['LOW'] || 0;
    let vulnerabilitiesBySeverityWarn = vulnerabilitiesBySeverity['WARN'] | 0;
    let vulnerabilitiesBySeverityInfo = vulnerabilitiesBySeverity['INFO'] | 0;

    const ciphers = auditData.data.testSSL.scanResult[0].ciphers ?? []
    const sortedCiphers = sortBySeverity(ciphers)

    const headerResponse = auditData.data.testSSL.scanResult[0].headerResponse ?? []
    const sortedResponses = sortBySeverity(headerResponse)

    return { uri_dest, uri_ins, browsing_history, start_time, end_time, browser, uri_refs, host, secureConnection, testSSL, firstSSLScanResult, vulnerabilitiesBySeverityCritical, vulnerabilitiesBySeverityHigh, vulnerabilitiesBySeverityMedium, vulnerabilitiesBySeverityLow, vulnerabilitiesBySeverityWarn, vulnerabilitiesBySeverityInfo, sortedProtocols, sortedVulnerabilitiesBySeverity, sortedCiphers, sortedResponses, unsafeForms }
}