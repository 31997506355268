export class ScanGateway {
    async fetchAuditData(siteUrl, noUseCache = false) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                noUseCache: noUseCache,
                url: siteUrl
            }),
        }
        try {
            const url = process.env.REACT_APP_ORION_API
            const response = await fetch(`${url}/audit`, requestOptions)
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            return await response.json()
        } catch (error) {
            console.error('Error fetching audit data:', error)
        }
    }
}
