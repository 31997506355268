import { useSelector } from "react-redux";
import { selectAuditData } from "../store/stepsSlice";


export function useCustomeThirdStepHook() {
    const auditData = useSelector(selectAuditData)

    const uriDest = auditData?.data?.uri_dest


    return {
        uriDest
    }
}