import React from 'react'
import SectionTitle from './SectionSubComp/SectionTitle'
import FulfillmentBar from './SectionSubComp/FulfillmentBar'
import HelpFrame from './SectionSubComp/HelpFrame'
export default function SectionCard({ title, explainations, actions, destination }) {
    const explainArea = explainations
    const actionsArea = actions
    return (
        <>
            <div id={destination} className="bg-white pb-4 mt-4 pt-10 px-8 rounded-xl mb-2">
                <SectionTitle value={title} destination={destination}></SectionTitle>
                <FulfillmentBar mark="yellow"></FulfillmentBar>
                <div className="flex justify-between">
                    <div className="w-full">
                        <div>
                            {explainArea}
                        </div>
                        <HelpFrame destination={destination}>
                            {actionsArea}
                        </HelpFrame>
                    </div>
                </div>
            </div>
        </>
    )
}
