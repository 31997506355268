import React from 'react'
import SectionCard from './SectionCard'
import { useDataBeacons } from './use-DataAndRetentionPeriod.hook'
import { nanoid } from "nanoid"
import SubsectionTitle from './SectionSubComp/SubsectionTitle'
import Paragraph from './SectionSubComp/Paragraph'
import TableWrapper from './SectionSubComp/TableWrapper'
import SubsectionSubtitle from './SectionSubComp/SubsectionSubtitle'


export default function DataAndRetentionPeriod({ destination }) {
    const title = "Données et durées de conservation"

    const { beaconsByList, cookiesByStorage, localStorage, uri_dest } = useDataBeacons()


    const beaconsList =
        Object.keys(beaconsByList).map((listName) => (
            <TableWrapper>
                <table className=" divide-gray-300">
                    <colgroup>
                        <col width="0%" />
                        <col width="100%" />
                        <col width="0%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">#</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">URL Exemple
                            </th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Freq.</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {beaconsByList[listName].map((beacon, index) => (
                            <React.Fragment key={nanoid()}>
                                <tr className="even:bg-gray-50">
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{index + 1}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate max-w-2xl text-ellipsis"
                                        title={beacon.url}><a href={beacon.url} className={'text-greenClara'}>{beacon.url}</a></td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{beacon.occurrances}</td>
                                </tr>
                                {beacon.query && (
                                    <tr className="even:bg-gray-50">
                                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3"></td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 code"
                                            colSpan="2">
                                            <pre><code>{JSON.stringify(beacon.query, null, 2)}</code></pre>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </TableWrapper>
        ))

    const explainations = <div>
        <SubsectionTitle value="Quelles données peuvent être détectées ?" />
        <Paragraph value="Le scanner détecte des données dans les cookies, les traceurs (pixels cachés, beacons ...) et dans le cache (la mémoire temporaire de votre navigateur).
            Ce scanner utilise des bases de données comprenant une liste de traceurs, cookies et autres pixels cachés connus pour tracer les internautes.
            S'il s'apparaissent ci-après, c'est qu'ils constituent en soit un moyen de traitement de données personnelles. Vous disposez ici de la possibilité de connaître la nature des données ainsi utiles à ce traçage." />

        <SubsectionTitle value={"Les traceurs et pixels cachés"} />
        <Paragraph value="Le scanner détecte la présence de balise, pixels cachés et traceurs en lisant le code source du site internet scanné ainsi que les informations qui sont déposés dans la 
        mémoire locale du navigateur durant la navigation (souvent l'oeuvre de scripts exécutés lors du chargement de la page).
        Ces informations sont ensuite comparées avec une base de données de traceurs connus. Si vous constatez que certains d'entre eux sont listés 
        ci après, c'est que le site internet collecte des informations sur la navigation des internautes. Le scanner peut également vous afficher le contenu de ces traceurs si cela s'avère pertinent."/>
        {Object.keys(beaconsByList).length > 0 ? beaconsList : <Paragraph value={"Aucun traceur n'a été détecté."} />}


        <SubsectionTitle value={"Les cookies"} />
        <Paragraph value="Les cookies peuvent être déposés durant la navigation et se rapporter à un autre nom de domaine. Lorsque c'est le cas, il peut aussi s'agir d'un indice de présence
            d'un moyen de traitement impliquant un transfert de données. La durée de vie des cookies publicitaires n'est sensé pas exécéder 13 mois. Soyez dont vigilant à cet aspect.
            Les cookies de session sont souvent techniques. Ils permettent de mettre en oeuvre une ou plusieurs fonctionnalité du site internet. C'est le cas par exemple pour les cookies qui contiennent des informations relatives à votre panier d'achat non encore soldé ou abandonné lorsque vous naviguez sur un site e-commerce.
            Veuillez noter que le scanner va considérer que les sous-domaines sont des domaines différents de celui de l'url du site que vous avez renseignée pour procéder au scan. Par exemple, du point de vue du scanner, www.olympe.legal n'est pas le même domaine que olympe.legal
            Par ailleurs, dans l'hypothèse où le site dispose d'un système de gestion du consentement qui conditionne le dépôt d'autres cookies au recueil préalable du consentement, alors ces cookies ne seront pas détectés avec cette version du scanner." />
        {Object.keys(beaconsByList).length <= 0 ? <Paragraph value="Aucun cookie n'a été détecté." /> : Object.keys(cookiesByStorage).map((index) => {
            const cookieList = cookiesByStorage[index];
            return (
                <div key={index}>
                    <SubsectionSubtitle>{`Cookies ${index === "false" ? 'liés au nom de domaine ' : 'liés à des domaines différents'}`} de {uri_dest.replace(/^https?:\/\//, '').replace(/^\//, '')}</SubsectionSubtitle>
                    {cookieList?.length > 0 ? (
                        <TableWrapper>
                            <table className="cookies divide-gray-300" width={'100%'}>
                                <colgroup>
                                    <col width="0%" />
                                    <col width="0%" />
                                    <col width="0%" />
                                    <col width="100%" />
                                    <col width="0%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className="notrunc py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">#</th>
                                        <th className="notrunc px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Hôte</th>
                                        <th className="notrunc px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Chemin</th>
                                        <th className="trunc px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Nom</th>
                                        <th className="notrunc px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Expiration en jours</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cookieList.map((cookie, index) => (
                                        <tr key={index} className="even:bg-gray-50">
                                            <td className="notrunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{index + 1}</td>
                                            <td className="notrunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                <a className={'text-greenClara'}
                                                    href={`http://${cookie?.domain}`}>{cookie?.domain}</a>
                                            </td>
                                            <td className="notrunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                <a className={'text-greenClara'}
                                                    href={`http://${cookie?.domain}${cookie?.path}`}>{cookie?.path}</a>
                                            </td>
                                            <td className="trunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{cookie?.name}</td>
                                            <td className="notrunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                {cookie?.session ? <em>session</em> : cookie.expiresDays}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table></TableWrapper>

                    ) : (
                        <Paragraph value={`Aucun cookie ${index.toLowerCase()} n'a été trouvé.`}></Paragraph>
                    )}
                </div>
            );
        })}
        <SubsectionTitle value={'Les Données persistantes en cache détectées'} />
        <Paragraph value="Lors de la navigation, des informations peuvent être déposées et persister dans votre navigateur jusqu'à ce que vous le fermiez. Le scanner détecte ces informations et vous affiche leur contenu, qui peut se présenter de manière un peu technique." />
        {Object.keys(localStorage).length > 0 ? (
            <TableWrapper>
                <table className="local-storage divide-gray-300" width={'100%'}>
                    <colgroup>
                        <col width="0%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="60%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="notrunc py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">#</th>
                            <th className="truncate px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Hôte</th>
                            <th className="trunc px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Clé</th>
                            <th className="trunc px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Valeur</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(localStorage).map((url, index) => (
                            Object.keys(localStorage[url]).map((key, subIndex) => (
                                <tr key={index + subIndex} className="even:bg-gray-50">
                                    <td className="notrunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{index * subIndex + subIndex + 1}</td>
                                    <td className="trunc whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <a className={'text-greenClara'} href={url} title={url}>
                                            {url.replace(/(^\w+:|^)\/\//, '')}
                                        </a>
                                    </td>
                                    <td className="trunc whitespace-nowrap px-3 py-4 text-sm text-gray-500">{key}</td>
                                    <td className="trunc whitespace-pre-wrap break-all leading-7 font-mono">
                                        {JSON.stringify(localStorage[url][key].value, null, 2)}
                                    </td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table></TableWrapper>

        ) : (
            <p className={'mb-4 text-base leading-6'}>Le stockage local a été trouvé vide lors du scan.</p>
        )
        }
    </div >
    const actions = <div>

    </div>
    return (
        <SectionCard title={title} explainations={explainations} actions={actions} destination={destination} />
    )
}
