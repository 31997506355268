export const initialAuditData = {
    data: {
        title: "",
        task_description: null,
        uri_ins:"",
        uri_refs:[""],
        uri_dest:"",
        uri_redirects:[""],
        secure_connection: {
            https_redirect:true,
            redirects:[""],
            https_support:true,
            https_error: null,
            http_error: null
        },
        testSSLError: null,
        testSSLErrorOutput: null,
        testSSLErrorCode: null,
        testSSL:{
            clientProblem1:[{
                id:"",
                severity:"",
                finding:""
            }],
            Invocation:"",
            at:"",
            version:"",
            openssl:"",
            startTime:"",
            scanResult:[{
                targetHost:"",
                ip:"",
                port:"",
                rDNS:"",
                service:"",
                pretest:[{
                    id:"",
                    severity:"",
                    finding:""
                }],
                protocols:[{id:"",severity:"",finding:""}],
                grease:[],
                ciphers:[{id:"",severity:"",cwe:"",finding:""}],
                pfs:[],
                serverPreferences:[{id:"",severity:"",finding:""}],
                serverDefaults:[{
                    id:"",
                    severity:"",
                    finding:""
                }],
                headerResponse:[{id:"",severity:"",finding:""}],
                vulnerabilities:[{id:"",severity:"",cve:"",cwe:"",finding:""}],
                cipherTests:[],
                browserSimulations:[]
            }],
            scanTime:0
        },
        host:"",
        script:{
            host:"",
            version:{
                npm:"",
                commit:""
            },
            config:{
                _:[""],
                json:true,
                output:false,
                max:0,
                sleep:3000,
                firstPartyUri:[],
                headless:true,
                screenshots:false,
                dnt:false,
                dntJs:false,
                overwrite:false,
                yaml:false,
                html:false,
                usePandoc:false,
                pdf:true,
                taskDescription:null,
                quiet:false,
                browserOptions:[],
                lang:"",
                pageTimeout:0,
                $0:"",
                testssl:true,
                testsslExecutable:"",
                url:""
            },
            cmd_args:"",
            environment:{},
            node_version:""
        },
        localStorage:{},
        cookies:[],
        beacons:[],
        links:{
            firstParty:[],
            thirdParty:[{
                href:"",
                inner_text:"",
                inner_html:""

            }],
            social:[{
                href:"",
                inner_text:"",
                inner_html:""
            }],
            keywords:[{
                href:"",
                inner_text:"",
                inner_html:""
            }]
        },
        unsafeForms:[],
        browser:{
            name:"",
            version:"",
            platform:{
                name:"",
                version:""
            },
            extra_headers:{
                dnt:0
            },
            preset_cookies:{}
        },
        browsing_history:[""],
        hosts:{
            requests:{
                firstParty:[""],
                thirdParty:[""]
            },
            beacons:{
                firstParty:[],
                thirdParty:[]
            },
            cookies:{
                firstParty:[],
                thirdParty:[]
            },
            localStorage:{
                firstParty:[],
                thirdParty:[]
            },
            links:{
                firstParty:[],
                thirdParty:[""]
            }},
        websockets:{},
        start_time:"",
        end_time:"",
        websocketLog:{}
    }
}
