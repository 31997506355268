import { LightBulbIcon } from '@heroicons/react/16/solid'

import React from 'react'
import SubsectionTitle from './SubsectionTitle'

export default function HelpFrame(props) {
    return (
        <div id={`solution-${props.destination}`} className='rounded-2xl border border-greenClara p-5'>
            <div className='flex flex-row'>
                <LightBulbIcon className='max-h-[2em] text-greenClara my-auto mr-3' />
                <SubsectionTitle value="Solutions et interprétation" />
            </div>
            {props.children}
        </div>
    )
}
