import { groupBy } from "lodash";
import { useSelector } from "react-redux";
import { selectAuditData } from "../../store/stepsSlice";

export function useDataBeacons() {
    const auditData = useSelector(selectAuditData)
    const uri_dest = auditData?.data?.uri_dest
    const beacons = auditData?.data?.beacons
    const beaconsByList = groupBy(beacons, 'listName')
    const cookies = auditData?.data?.cookies
    const cookiesByStorage = groupBy(cookies, 'firstPartyStorage')
    const localStorage = auditData?.data?.localStorage
    return { beaconsByList, cookiesByStorage, localStorage, uri_dest }
}