export const useUrlManager = () => {

    const isValidSecureUrl = (url) => {
        const splitUrl = url.split('://');
        if (splitUrl[0] !== 'https') {
            return false
        }
        const domain = splitUrl[1];
        const domainSplit = domain.split('.');

        if (domainSplit[1] === '') {
            return false
        }

        const regex = /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}(\b|\/[-a-zA-Z0-9@:%_+.~#?&/=]*)$/;
        return regex.test(url)
    }
    const prependHttpsIfNeeded = value => {
        if (value.startsWith('http://')) {
            return value.replace('http://', 'https://')
        }
        if (value.startsWith('https://')) {
            return value
        }
        return 'https://' + value
    };
    return {
         prependHttpsIfNeeded, isValidSecureUrl
    }
}
