import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setAuditData, setSiteUrl, setStep} from "../store/stepsSlice";

export const useNavbarHook = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const reset = () => {
        navigate('/')
        dispatch(setSiteUrl(''))
        dispatch(setAuditData())
        dispatch(setStep(0))
    }
    return {
        reset
    }
}
