import React from 'react'
import SectionCard from './SectionCard'
import { useRecipientsAndTransfersHook } from './use-RecipientsAndTransfers.hook'
import { nanoid } from 'nanoid'
import SubsectionTitle from './SectionSubComp/SubsectionTitle'
import Paragraph from './SectionSubComp/Paragraph'
import SubsectionSubtitle from './SectionSubComp/SubsectionSubtitle'
import TableWrapper from './SectionSubComp/TableWrapper'
export default function RecipientsAndTransfers({ destination }) {
    const { hosts, links } = useRecipientsAndTransfersHook()
    const explainArea = <div>
        <SubsectionTitle value="Quels types de destinataires et de transferts peuvent être détectés ?" />
        <Paragraph value="Le scanner détecte les transferts à partir des liens présents dans le code source du site ou de l'application pointant vers des services tiers.
            Ainsi, ce sont notamment les solutions de mesures de trafic et de navigation cachés qui peuvent apparaître dans les résultats. Le scanner distingue les tierces et première parties.
            Les tierces parties sont des services dont l'URL est différente de celle du site que vous avez renseignée (qui est donc la première partie).
            Le scanner détecte aussi la présence de l'emploi de réseaux sociaux, ce qui peut impliquer soit des traitements à responsabilité conjointe ('Pages Facebook', 'Compte Twitter', etc.).
            L'emploi de réseaux sociaux sur un site internet peut notamment impliquer aussi l'existence de pixels cachés, qui permettent de fournir des statistiques de mesures d'audience ou aussi d'effectuer du ciblage publicitaire.
            Dans sa prochaine version, ce scanner vous fournira également les informations sur l'hébergeur et le fournisseur du nom de domaine du site internet." />

        <SubsectionTitle value="Requêtes HTTP effectuées depuis le site internet" />
        <Paragraph value="Il s'agit des ici des appels effectués vers des services depuis le site scanné. Comme évoqué ci-avant il s'agit de détecter notamment le chargement de scripts externes pouvant extraire des informations concernant les internautes visitant le site. Soyez donc vigilant s'il apparait des résultats que vous n'attendiez pas ou qui vous sont étrangers." />

        <SubsectionSubtitle>Les requêtes effectuées vers le même domaine que celui du site scanné</SubsectionSubtitle>
        <Paragraph value={<span>Au total <span className='font-bold text-xl'>{hosts?.requests?.firstParty?.length}</span> url{hosts?.requests?.firstParty?.length > 1 ? 's' : ''} relevant du même nom de domaine du site scanné {hosts?.requests?.firstParty?.length > 1 ? 'ont' : 'a'}  été appelée{hosts?.requests?.firstParty?.length > 1 ? 's' : ''}  lors du chargement de la page par le scanner. En voici le détail :
            <ol className={'pl-8 mb-4 list-decimal'}>
                {hosts?.requests?.firstParty?.map((host) => (
                    <li className={'text-base leading-6 mt-1'} key={nanoid()}>
                        <a className={'text-greenClara text-lg leading-8 font-semibold'} href={`http://${host}`}>{host}</a>
                    </li>
                ))}
            </ol></span>} />

        <SubsectionSubtitle>Les requêtes effectuées vers des noms de domaines autres que celui du site scanné</SubsectionSubtitle>
        <Paragraph value={<span>Il s'agit ici des requêtes les plus à risque.
            Au total <span className='font-bold text-xl'>{hosts?.requests?.thirdParty?.length}</span> services tiers (y compris ceux relevant des sous-domaines tel que "<span>www</span>.nom-de-domaine.fr") ont été appelés lors du chargement de la page par le scanner. En voici la liste :
            <ol className={'pl-8 mb-4 list-decimal'}>
                {hosts?.requests?.thirdParty?.map((host) => (
                    <li className={'text-base leading-6 mt-1'} key={nanoid()}>
                        <a className={'text-greenClara text-lg leading-8 font-semibold'} href={`http://${host}`}>{host}</a>
                    </li>
                ))}
            </ol></span>} />

        <SubsectionTitle value="L'utilisation de réseaux sociaux" />
        <Paragraph value="L'emploi de certains réseaux sociaux peut impliquer l'existence de traitements sous responsabilité parfois partagée avec les éditeurs (facebook Pages ...) mais également le dépôt de certains traceurs et pixels cachés. Voici la liste des réseaux sociaux détectés lors du scan :" />

        {links?.social?.length > 0 ? (
            <TableWrapper>
                <table className="use-of-social-media-and-collaboration-platforms divide-gray-300">
                    <colgroup>
                        <col width={'100%'} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className={'py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-3'}>URLs
                                des différents réseaux sociaux détectéss
                            </th>
                        </tr>
                    </thead>
                    <tbody className={'bg-white'}>
                        {links?.social?.map((social) => (
                            <tr key={nanoid()} className="even:bg-gray-50">
                                <td className="trunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                    <a className={'text-greenClara text-lg leading-8 font-semibold'} href={social.href}>{social.href}</a>
                                </td>
                                <td className="notrunc whitespace-nowrap px-3 py-4 text-sm text-gray-500">{social.inner_text}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableWrapper>

        ) : (
            <p className={'mb-4 text-base leading-6'}>Aucun lien correspondant n'a été trouvé.</p>
        )}
    </div>

    const actionsArea = <div></div>

    return (
        <SectionCard title="Transferts de données et destinataires" explainations={explainArea} actions={actionsArea} destination={destination}></SectionCard>
    )
}
