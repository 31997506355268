import HeaderTitle from "./TitleBlockHeader/HeaderTitle";
import React from "react";
import FulfillmentBar from "./SectionSubComp/FulfillmentBar";
import GlobalScore from "./SectionSubComp/GlobalScore";
import { useHeaderHook } from "./use-header.hook";
import SubsectionTitle from "./SectionSubComp/SubsectionTitle";

function setColor(score, isGlobal) {
    if (isGlobal === false) {
        if (score === 1) {
            return "red"
        } else if (score === 2) {
            return "orange"
        } else if (score === 3) {
            return "yellow"
        } else if (score === 4) {
            return "lightgreen"
        } else if (score === 5) {
            return "green"
        } else {
            return "green"
        }
    } else {
        if (score <= 20) {
            return "red"
        } else if (score <= 40) {
            return "orange"
        } else if (score <= 60) {
            return "yellow"
        } else if (score <= 80) {
            return "lightgreen"
        } else if (score <= 100) {
            return "green"
        } else {
            return "green"
        }
    }

}

function TitleBlockHeader() {
    const { dataScore,
        transfersScore,
        securityScore,
        globalScore } = useHeaderHook()


    return <header id="title-block-header">
        <section className="flex flex-row justify-between mb-20">

            <div className="flex flex-col">
                <SubsectionTitle value="Naviguez dans le rapport"></SubsectionTitle>
                <div className="flex flex-row">
                    <div className="my-1">
                        <a href="#info" className="text-blackText underline text-xl font-semibold leading-7">Informations générales</a>

                    </div>
                    <div>

                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <div className="my-1">
                        <a href="#data" className="text-blackText underline text-xl font-semibold leading-7">Données et durées de conservation</a>
                    </div>
                    <div className="ml-5 -mt-[5px]">
                        <FulfillmentBar mark={setColor(dataScore, false)}></FulfillmentBar>
                    </div>
                </div>
                <div className="flex flex-row justify-between -mt-[5px]">
                    <div className="my-1">
                        <a href="#transfers" className="text-blackText underline text-xl font-semibold leading-7">Destinataires et transferts de données</a>
                    </div>
                    <div className="ml-5 -mt-[4px]">
                        <FulfillmentBar mark={setColor(transfersScore, false)}></FulfillmentBar>
                    </div>
                </div>
                <div className="flex flex-row justify-between -mt-[5px]">
                    <div className="my-1">
                        <a href="#security" className="text-blackText underline text-xl font-semibold leading-7">Sécurité</a>

                    </div>
                    <div className="ml-5 -mt-[4px]">
                        <FulfillmentBar mark={setColor(securityScore, false)}></FulfillmentBar>
                    </div>
                </div>
                <div className="flex flex-row justify-between -mt-[5px]">
                    <div className="my-1">
                        <p className="text-gray-500 text-xl font-semibold leading-7">Mentions d'information</p>
                    </div>
                    <div className="my-1 ml-5 text-gray-500">
                        <p>(prochainement disponible)</p>
                    </div>
                </div>
            </div>

            <div className="px-3 flex flex-col pb-2 items-center">
                <div className="max-w-[300px] text-center">
                    <SubsectionTitle value="Score d'innocuité face au risque RGPD"></SubsectionTitle>
                </div>
                <GlobalScore mark={setColor(globalScore, true)} />
                <div className="-mt-[36px] text-center text-4xl font-['Figtree'] font-semibold">
                    <p>{globalScore} %</p>
                </div>
            </div>
        </section>

        <HeaderTitle />
    </header>
}

export default TitleBlockHeader;
