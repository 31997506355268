import React from 'react'

export default function GlobalScore({ mark }) {
    const score = mark
    if (score === "red") {
        return <svg width="208" height="135" viewBox="0 0 208 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M195.664 134.668C194.124 133.927 193.467 132.102 194.152 130.544C199.145 119.283 201.666 107.28 201.666 94.8124C201.666 87.3524 200.743 79.9685 198.908 72.8127C198.48 71.1592 199.431 69.4581 201.076 68.983C202.788 68.4793 204.595 69.4961 205.033 71.2257C207.001 78.9042 207.99 86.8203 207.99 94.8219C207.99 108.183 205.28 121.05 199.935 133.119C199.212 134.754 197.262 135.467 195.655 134.678L195.664 134.668Z" fill="#0ACF83" />
            <path d="M196.254 64.2504C189.939 47.1068 178.336 32.2155 163.204 21.8286C161.796 20.8593 161.397 18.9587 162.319 17.5237C163.28 16.0127 165.306 15.5946 166.78 16.6114C182.996 27.749 195.427 43.7047 202.198 62.0837C202.817 63.7658 201.913 65.6189 200.211 66.1795C198.584 66.7117 196.844 65.8564 196.254 64.2599V64.2504Z" fill="#B7CD31" />
            <path d="M158.819 15.4045C157.963 16.887 156.099 17.4097 154.587 16.6114C141.88 9.87371 127.605 6.32906 113.12 6.32906C98.6345 6.32906 85.053 9.70266 72.5937 16.1172C71.072 16.8965 69.2079 16.3453 68.3804 14.8628C67.5054 13.3043 68.0951 11.3182 69.6834 10.5009C83.0462 3.62068 97.9878 0 113.12 0C128.251 0 143.944 3.80124 157.564 11.0236C159.143 11.8599 159.713 13.8555 158.819 15.4045Z" fill="#FFC701" />
            <path d="M26.019 66.208C24.3166 65.6474 23.413 63.7943 24.0312 62.1122C31.0122 43.1155 43.9755 26.7892 60.9429 15.6041C62.4361 14.6158 64.4524 15.0719 65.3845 16.6019C66.2785 18.0559 65.841 19.947 64.4239 20.8878C48.5883 31.3222 36.5 46.5557 29.9755 64.2789C29.3859 65.8754 27.6454 66.7307 26.019 66.1985V66.208Z" fill="#FF8A00" />
            <path d="M30.5747 134.668C28.9674 135.448 27.0177 134.744 26.2948 133.11C20.9402 121.041 18.2391 108.174 18.2391 94.8124C18.2391 86.8013 19.2378 78.8757 21.2065 71.1877C21.6535 69.4581 23.4511 68.4508 25.1631 68.9544C26.8084 69.4391 27.7595 71.1307 27.3315 72.7842C25.4959 79.9495 24.5639 87.3429 24.5639 94.8124C24.5639 107.28 27.0938 119.283 32.0775 130.544C32.7717 132.103 32.106 133.927 30.5652 134.668H30.5747Z" fill="#CB0505" />
            <g filter="url(#filter0_d_1_68)">
                <path d="M21.9389 92.3986C16.4606 92.3986 12 96.8555 12 102.329C12 107.803 16.4511 112.26 21.9389 112.26C27.4171 112.26 31.8777 107.803 31.8777 102.329C31.8777 96.8555 27.4171 92.3986 21.9389 92.3986ZM21.9389 110.093C17.6495 110.093 14.1685 106.606 14.1685 102.329C14.1685 98.0529 17.6495 94.5653 21.9389 94.5653C26.2188 94.5653 29.7092 98.0434 29.7092 102.329C29.7092 106.615 26.2188 110.093 21.9389 110.093Z" fill="#CB0505" />
            </g>
            <path d="M21.8343 94.4703C17.5449 94.4703 14.0639 97.9484 14.0639 102.234C14.0639 106.52 17.5449 109.998 21.8343 109.998C26.1237 109.998 29.6046 106.511 29.6046 102.234C29.6046 97.9579 26.1141 94.4703 21.8343 94.4703ZM22.481 105.418C22.2718 105.465 22.053 105.484 21.8343 105.484C20.0367 105.484 18.572 104.03 18.572 102.234C18.572 101.75 18.6766 101.284 18.8859 100.856C19.428 99.716 20.5788 98.9842 21.8343 98.9842C23.6318 98.9842 25.087 100.438 25.087 102.234C25.087 103.774 23.9932 105.114 22.481 105.418Z" fill="white" />
            <defs>
                <filter id="filter0_d_1_68" x="0.66" y="83.8886" width="42.5577" height="42.5415" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.83" />
                    <feGaussianBlur stdDeviation="5.67" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.796078 0 0 0 0 0.0196078 0 0 0 0 0.0196078 0 0 0 0.75 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_68" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_68" result="shape" />
                </filter>
            </defs>
        </svg>
    } else if (score === "orange") {
        return <svg width="190" height="135" viewBox="0 0 190 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M177.658 134.668C176.115 133.927 175.458 132.102 176.144 130.544C181.143 119.283 183.667 107.28 183.667 94.8123C183.667 87.3524 182.743 79.9685 180.905 72.8127C180.477 71.1592 181.429 69.4581 183.077 68.9829C184.791 68.4793 186.6 69.4961 187.038 71.2257C189.01 78.9042 190 86.8203 190 94.8219C190 108.183 187.286 121.05 181.934 133.119C181.21 134.754 179.258 135.467 177.648 134.678L177.658 134.668Z" fill="#0ACF83" />
            <path d="M178.239 64.2504C171.915 47.1068 160.297 32.2155 145.145 21.8286C143.736 20.8593 143.336 18.9587 144.259 17.5237C145.221 16.0127 147.25 15.5946 148.726 16.6114C164.963 27.749 177.41 43.7047 184.191 62.0837C184.81 63.7658 183.905 65.6189 182.2 66.1795C180.572 66.7117 178.829 65.8564 178.239 64.2599V64.2504Z" fill="#B7CD31" />
            <path d="M140.764 15.4045C139.907 16.887 138.041 17.4097 136.526 16.6114C123.803 9.87371 109.509 6.32906 95.0048 6.32906C80.5007 6.32906 66.9014 9.70266 54.4258 16.1173C52.9021 16.8965 51.0355 16.3453 50.207 14.8628C49.3309 13.3043 49.9213 11.3182 51.5117 10.5009C64.892 3.62068 79.8531 0 95.0048 0C110.156 0 125.87 3.80124 139.507 11.0236C141.088 11.8599 141.66 13.8555 140.764 15.4045Z" fill="#FFC701" />
            <path d="M7.79009 66.208C6.08541 65.6474 5.1807 63.7943 5.79971 62.1122C12.7898 43.1155 25.7701 26.7892 42.7598 15.6041C44.2549 14.6157 46.2739 15.0719 47.2072 16.6019C48.1024 18.0559 47.6643 19.947 46.2453 20.8878C30.389 31.3222 18.2848 46.5557 11.7518 64.2789C11.1613 65.8754 9.41858 66.7307 7.79009 66.1985V66.208Z" fill="#FF8A00" />
            <path d="M12.3518 134.668C10.7423 135.448 8.79004 134.744 8.06626 133.11C2.70463 121.041 0 108.174 0 94.8123C0 86.8012 0.999951 78.8756 2.97128 71.1876C3.41888 69.4581 5.21879 68.4507 6.93299 68.9544C8.58052 69.4391 9.53286 71.1306 9.10431 72.7842C7.2663 79.9495 6.33302 87.3429 6.33302 94.8123C6.33302 107.28 8.86622 119.283 13.8564 130.544C14.5517 132.102 13.885 133.927 12.3422 134.668H12.3518Z" fill="#CB0505" />
            <g filter="url(#filter0_d_1_69)">
                <path d="M22.6084 28.6993C17.1229 28.6993 12.6565 33.1563 12.6565 38.6301C12.6565 44.1039 17.1134 48.5608 22.6084 48.5608C28.0938 48.5608 32.5603 44.1039 32.5603 38.6301C32.5603 33.1563 28.0938 28.6993 22.6084 28.6993ZM22.6084 46.3941C18.3134 46.3941 14.8278 42.9065 14.8278 38.6301C14.8278 34.3537 18.3134 30.866 22.6084 30.866C26.8939 30.866 30.3889 34.3442 30.3889 38.6301C30.3889 42.916 26.8939 46.3941 22.6084 46.3941Z" fill="#FF8A00" />
            </g>
            <path d="M22.5132 30.771C18.2181 30.771 14.7326 34.2491 14.7326 38.535C14.7326 42.8209 18.2181 46.2991 22.5132 46.2991C26.8082 46.2991 30.2937 42.8114 30.2937 38.535C30.2937 34.2586 26.7987 30.771 22.5132 30.771ZM23.1608 41.7186C22.9512 41.7661 22.7322 41.7851 22.5132 41.7851C20.7133 41.7851 19.2467 40.3311 19.2467 38.535C19.2467 38.0504 19.3514 37.5847 19.5609 37.1571C20.1038 36.0167 21.2561 35.285 22.5132 35.285C24.3131 35.285 25.7701 36.7389 25.7701 38.535C25.7701 40.0745 24.675 41.4145 23.1608 41.7186Z" fill="white" />
            <defs>
                <filter id="filter0_d_1_69" x="1.31649" y="20.1893" width="42.5837" height="42.5415" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.83" />
                    <feGaussianBlur stdDeviation="5.67" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.541176 0 0 0 0 0 0 0 0 0.75 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_69" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_69" result="shape" />
                </filter>
            </defs>
        </svg>
    } else if (score === "yellow") {
        return <svg width="190" height="151" viewBox="0 0 190 151" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M177.658 150.667C176.115 149.923 175.458 148.091 176.144 146.527C181.143 135.222 183.667 123.173 183.667 110.657C183.667 103.168 182.743 95.7556 180.905 88.5721C180.477 86.9121 181.429 85.2045 183.077 84.7275C184.791 84.2219 186.6 85.2426 187.038 86.9789C189.01 94.6871 190 102.634 190 110.666C190 124.079 187.286 136.996 181.934 149.112C181.21 150.753 179.258 151.468 177.648 150.677L177.658 150.667Z" fill="#0ACF83" />
            <path d="M178.239 79.9766C171.915 62.7667 160.297 47.8177 145.145 37.3906C143.736 36.4176 143.336 34.5096 144.259 33.0691C145.221 31.5522 147.25 31.1325 148.726 32.1533C164.963 43.334 177.41 59.3514 184.191 77.8015C184.81 79.4901 183.905 81.3504 182.2 81.9132C180.572 82.4474 178.829 81.5889 178.239 79.9862V79.9766Z" fill="#B7CD31" />
            <path d="M140.764 30.9417C139.907 32.4299 138.041 32.9546 136.526 32.1532C123.803 25.3895 109.509 21.8311 95.0047 21.8311C80.5007 21.8311 66.9014 25.2178 54.4258 31.6572C52.9021 32.4394 51.0355 31.8861 50.207 30.3979C49.3309 28.8334 49.9213 26.8395 51.5117 26.0191C64.892 19.1122 79.8531 15.4776 95.0047 15.4776C110.156 15.4776 125.87 19.2935 139.507 26.5438C141.088 27.3833 141.66 29.3867 140.764 30.9417Z" fill="#FFC701" />
            <path d="M7.79009 81.9418C6.08541 81.379 5.1807 79.5187 5.79971 77.8301C12.7898 58.7599 25.7701 42.3704 42.7598 31.142C44.2549 30.1499 46.2739 30.6078 47.2072 32.1437C48.1024 33.6033 47.6643 35.5017 46.2453 36.4462C30.389 46.921 18.2848 62.2134 11.7518 80.0052C11.1613 81.6079 9.41858 82.4665 7.79009 81.9323V81.9418Z" fill="#FF8A00" />
            <path d="M12.3518 150.667C10.7423 151.449 8.79003 150.743 8.06626 149.103C2.70463 136.987 0 124.07 0 110.657C0 102.615 0.999949 94.6585 2.97128 86.9407C3.41887 85.2045 5.21879 84.1933 6.93299 84.6989C8.58052 85.1854 9.53285 86.8835 9.1043 88.5434C7.2663 95.7365 6.33301 103.158 6.33301 110.657C6.33301 123.173 8.86622 135.222 13.8564 146.527C14.5517 148.091 13.885 149.923 12.3422 150.667H12.3518Z" fill="#CB0505" />
            <g filter="url(#filter0_d_1_71)">
                <path d="M95.719 9C90.2336 9 85.7671 13.4742 85.7671 18.9691C85.7671 24.4641 90.224 28.9383 95.719 28.9383C101.204 28.9383 105.671 24.4641 105.671 18.9691C105.671 13.4742 101.204 9 95.719 9ZM95.719 26.7632C91.424 26.7632 87.9384 23.2621 87.9384 18.9691C87.9384 14.6762 91.424 11.1751 95.719 11.1751C100.004 11.1751 103.5 14.6667 103.5 18.9691C103.5 23.2716 100.004 26.7632 95.719 26.7632Z" fill="#FFC701" />
            </g>
            <path d="M95.6142 11.0701C91.3192 11.0701 87.8337 14.5617 87.8337 18.8642C87.8337 23.1667 91.3192 26.6583 95.6142 26.6583C99.9093 26.6583 103.395 23.1572 103.395 18.8642C103.395 14.5713 99.8997 11.0701 95.6142 11.0701ZM96.2618 22.0601C96.0523 22.1078 95.8333 22.1268 95.6142 22.1268C93.8143 22.1268 92.3477 20.6673 92.3477 18.8642C92.3477 18.3777 92.4525 17.9102 92.662 17.4809C93.2048 16.3361 94.3572 15.6016 95.6142 15.6016C97.4142 15.6016 98.8712 17.0612 98.8712 18.8642C98.8712 20.4097 97.776 21.7548 96.2618 22.0601Z" fill="white" />
            <defs>
                <filter id="filter0_d_1_71" x="74.4271" y="0.49" width="42.5838" height="42.6183" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.83" />
                    <feGaussianBlur stdDeviation="5.67" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.780392 0 0 0 0 0.00392157 0 0 0 0.75 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_71" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_71" result="shape" />
                </filter>
            </defs>
        </svg>
    } else if (score === "lightgreen") {
        return <svg width="190" height="136" viewBox="0 0 190 136" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M177.658 135.666C176.115 134.919 175.458 133.081 176.144 131.511C181.143 120.166 183.667 108.075 183.667 95.5147C183.667 87.9995 182.743 80.5609 180.905 73.3521C180.477 71.6863 181.429 69.9726 183.077 69.494C184.791 68.9866 186.6 70.0109 187.038 71.7533C189.01 79.4887 190 87.4634 190 95.5243C190 108.985 187.286 121.947 181.934 134.105C181.21 135.752 179.258 136.47 177.648 135.675L177.658 135.666Z" fill="#0ACF83" />
            <path d="M178.239 64.7359C171.915 47.4653 160.297 32.4637 145.145 21.9999C143.736 21.0234 143.336 19.1086 144.259 17.6631C145.221 16.1409 147.25 15.7197 148.726 16.744C164.963 27.9642 177.41 44.038 184.191 62.5531C184.81 64.2476 183.905 66.1145 182.2 66.6793C180.572 67.2154 178.829 66.3538 178.239 64.7454V64.7359Z" fill="#B7CD31" />
            <path d="M140.764 15.5186C139.907 17.0121 138.041 17.5387 136.526 16.7345C123.803 9.94689 109.509 6.37597 95.0048 6.37597C80.5007 6.37597 66.9014 9.77455 54.4258 16.2366C52.9021 17.0217 51.0355 16.4664 50.207 14.9729C49.3309 13.4029 49.9213 11.4021 51.5117 10.5787C64.892 3.64754 79.8531 0 95.0048 0C110.156 0 125.87 3.82943 139.507 11.1053C141.088 11.9477 141.66 13.9582 140.764 15.5186Z" fill="#FFC701" />
            <path d="M7.79009 66.7081C6.08541 66.1432 5.1807 64.2764 5.79971 62.5819C12.7898 43.4445 25.7701 26.9973 42.7598 15.7293C44.2549 14.7336 46.2739 15.1932 47.2072 16.7345C48.1024 18.1992 47.6643 20.1044 46.2453 21.0521C30.389 31.5638 18.2848 46.9101 11.7518 64.7646C11.1613 66.373 9.41858 67.2346 7.79009 66.6985V66.7081Z" fill="#FF8A00" />
            <path d="M12.3518 135.666C10.7423 136.451 8.79004 135.742 8.06626 134.096C2.70463 121.937 0 108.975 0 95.5147C0 87.4442 0.999951 79.46 2.97128 71.715C3.41888 69.9726 5.21879 68.9578 6.93299 69.4652C8.58052 69.9534 9.53286 71.6576 9.10431 73.3233C7.2663 80.5417 6.33301 87.9899 6.33301 95.5147C6.33301 108.075 8.86622 120.166 13.8564 131.511C14.5517 133.081 13.885 134.919 12.3422 135.666H12.3518Z" fill="#CB0505" />
            <g filter="url(#filter0_d_1_72)">
                <path d="M167.249 28.4237C161.64 28.4237 157.078 33.0094 157.078 38.6482C157.078 44.287 161.64 48.8727 167.249 48.8727C172.858 48.8727 177.42 44.287 177.42 38.6482C177.42 33.0094 172.858 28.4237 167.249 28.4237ZM167.249 46.6421C162.858 46.6421 159.297 43.052 159.297 38.6482C159.297 34.2444 162.858 30.6543 167.249 30.6543C171.639 30.6543 175.201 34.2348 175.201 38.6482C175.201 43.0616 171.629 46.6421 167.249 46.6421Z" fill="#B7CD31" />
            </g>
            <path d="M167.249 30.6639C162.858 30.6639 159.297 34.2444 159.297 38.6578C159.297 43.0712 162.858 46.6517 167.249 46.6517C171.639 46.6517 175.201 43.0616 175.201 38.6578C175.201 34.254 171.629 30.6639 167.249 30.6639ZM167.915 41.9415C167.696 41.9893 167.477 42.0085 167.249 42.0085C165.411 42.0085 163.916 40.515 163.916 38.6578C163.916 38.16 164.02 37.6813 164.23 37.2409C164.782 36.0634 165.963 35.3166 167.249 35.3166C169.087 35.3166 170.582 36.8101 170.582 38.6674C170.582 40.247 169.468 41.6351 167.915 41.9511V41.9415Z" fill="white" />
            <defs>
                <filter id="filter0_d_1_72" x="145.738" y="19.9137" width="43.0218" height="43.129" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.83" />
                    <feGaussianBlur stdDeviation="5.67" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.717647 0 0 0 0 0.803922 0 0 0 0 0.192157 0 0 0 0.75 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_72" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_72" result="shape" />
                </filter>
            </defs>
        </svg>
    } else if (score === "green") {
        return <svg width="209" height="135" viewBox="0 0 209 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M178.028 134.668C176.482 133.927 175.824 132.102 176.511 130.544C181.521 119.283 184.05 107.28 184.05 94.8123C184.05 87.3524 183.124 79.9685 181.282 72.8127C180.853 71.1591 181.807 69.4581 183.458 68.9829C185.176 68.4793 186.989 69.4961 187.428 71.2257C189.404 78.9042 190.396 86.8202 190.396 94.8219C190.396 108.183 187.676 121.05 182.313 133.119C181.588 134.754 179.631 135.467 178.019 134.678L178.028 134.668Z" fill="#0ACF83" />
            <path d="M178.62 64.2504C172.283 47.1068 160.64 32.2155 145.457 21.8287C144.045 20.8593 143.644 18.9587 144.57 17.5237C145.534 16.0127 147.566 15.5946 149.046 16.6114C165.317 27.749 177.79 43.7048 184.584 62.0837C185.205 63.7658 184.298 65.6189 182.59 66.1796C180.958 66.7117 179.212 65.8564 178.62 64.2599V64.2504Z" fill="#B7CD31" />
            <path d="M141.058 15.4045C140.199 16.887 138.328 17.4096 136.811 16.6114C124.061 9.87369 109.737 6.32909 95.2028 6.32909C80.6685 6.32909 67.0409 9.70268 54.5393 16.1173C53.0124 16.8965 51.1419 16.3453 50.3117 14.8628C49.4337 13.3043 50.0254 11.3182 51.6191 10.5009C65.0273 3.62067 80.0196 0 95.2028 0C110.386 0 126.132 3.80121 139.798 11.0236C141.382 11.8598 141.955 13.8555 141.058 15.4045Z" fill="#FFC701" />
            <path d="M7.80633 66.208C6.0981 65.6473 5.1915 63.7942 5.81181 62.1122C12.8165 43.1155 25.8239 26.7892 42.8489 15.6041C44.3472 14.6157 46.3703 15.0719 47.3056 16.6019C48.2026 18.0559 47.7637 19.947 46.3417 20.8878C30.4523 31.3222 18.3229 46.5557 11.7763 64.2789C11.1846 65.8754 9.43822 66.7307 7.80633 66.1986V66.208Z" fill="#FF8A00" />
            <path d="M12.3775 134.668C10.7647 135.448 8.80836 134.744 8.08308 133.11C2.71026 121.041 0 108.174 0 94.8123C0 86.8012 1.00203 78.8757 2.97747 71.1877C3.426 69.4581 5.22967 68.4507 6.94744 68.9544C8.59841 69.4391 9.55273 71.1306 9.12329 72.7842C7.28145 79.9495 6.34622 87.3429 6.34622 94.8123C6.34622 107.28 8.8847 119.283 13.8853 130.544C14.582 132.102 13.914 133.927 12.368 134.668H12.3775Z" fill="#CB0505" />
            <g filter="url(#filter0_d_1_70)">
                <path d="M187.027 92.3986C181.531 92.3986 177.055 96.8555 177.055 102.329C177.055 107.803 181.521 112.26 187.027 112.26C192.524 112.26 197 107.803 197 102.329C197 96.8555 192.524 92.3986 187.027 92.3986ZM187.027 110.093C182.723 110.093 179.231 106.606 179.231 102.329C179.231 98.0529 182.723 94.5653 187.027 94.5653C191.322 94.5653 194.824 98.0434 194.824 102.329C194.824 106.615 191.322 110.093 187.027 110.093Z" fill="#0ACF83" />
            </g>
            <path d="M186.922 94.4703C182.618 94.4703 179.126 97.9484 179.126 102.234C179.126 106.52 182.618 109.998 186.922 109.998C191.226 109.998 194.719 106.511 194.719 102.234C194.719 97.9579 191.217 94.4703 186.922 94.4703ZM187.571 105.418C187.361 105.465 187.142 105.484 186.922 105.484C185.119 105.484 183.649 104.03 183.649 102.234C183.649 101.75 183.754 101.284 183.964 100.856C184.508 99.716 185.663 98.9842 186.922 98.9842C188.726 98.9842 190.186 100.438 190.186 102.234C190.186 103.774 189.089 105.114 187.571 105.418Z" fill="white" />
            <defs>
                <filter id="filter0_d_1_70" x="165.715" y="83.8886" width="42.6253" height="42.5415" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.83" />
                    <feGaussianBlur stdDeviation="5.67" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.811765 0 0 0 0 0.513726 0 0 0 0.75 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_70" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_70" result="shape" />
                </filter>
            </defs>
        </svg>
    } else {
        return "Score non disponible"
    }
}