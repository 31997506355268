import { useUrlManager } from "../utils/useUrlManager";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setStep } from "../store/stepsSlice";
import { fetchData } from "../store/useCases/fetchData";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS, UrlSearchParams } from "../utils/CONSTANS";


export const useFirstStepHook = () => {
    const { isValidSecureUrl, prependHttpsIfNeeded } = useUrlManager()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [inputValue, setInputValue] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    async function performUrlSearch(e) {
        e.preventDefault()
        const urlFormValue = e.target[0].value.toLowerCase()
        const url = prependHttpsIfNeeded(urlFormValue)
        if (!isValidSecureUrl(url)) return
        const fetchDataResponse = await dispatch(fetchData({ siteUrl: url }))
        if (fetchData.fulfilled.match(fetchDataResponse)) {
            navigate(`${ROUTE_PATHS.results}?${UrlSearchParams}=${fetchDataResponse.meta.arg.siteUrl}`)
        } else {
            dispatch(setStep(0))
        }
    }

    const updateInputValue = (e) => {
        setInputValue(e.target.value)
        isValidSecureUrl(prependHttpsIfNeeded(e.target.value)) ? handleErrorMessage('') : handleErrorMessage('Veuillez respecter le format www.monsite.fr')
    }

    const handleErrorMessage = (message) => {
        setErrorMessage(message)
    }

    return {
        updateInputValue,
        performUrlSearch,
        inputValue,
        errorMessage,
    }
}
