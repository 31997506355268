import { useSelector } from "react-redux";
import { selectAuditData } from "../../../store/stepsSlice";

export function useHeaderTitleHook() {
    const auditData = useSelector(selectAuditData)

    const title = auditData?.data?.title
    const start_time = auditData?.data?.start_time
    const uri_ins = auditData?.data?.uri_ins;
    return { title, start_time, uri_ins };
}
