import React from 'react'
import IconFeatureButton from './IconFeatureButton'
import docAnalysisIcon from '../assets/img/DocAnalysisIcon.png'
import OlympeLogo from './OlympeLogo'
import {useNavbarHook} from "./use-navbar.hook";

export default function NavSideBar() {
    const {reset} = useNavbarHook()
  return (
    <nav className='bg-primary text-white h-100% min-h-screen w-20 flex flex-col pt-3'>
      <OlympeLogo />
      <IconFeatureButton action={reset} iconImgUrl={docAnalysisIcon} featureName="Analyse de document" />
    </nav>
  )
}
