import React from 'react'

export default function FulfillmentBar({ mark }) {
    const advance = mark
    function computeAdvanceBar() {
        if (advance === "red") {
            return <svg width="200" height="8" viewBox="0 0 200 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="200" height="8" rx="4" fill="#E9E9E9" />
                <rect width="40" height="8" rx="4" fill="#CB0505" />
            </svg>

        } else if (advance === "orange") {
            return <svg width="200" height="8" viewBox="0 0 200 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="200" height="8" rx="4" fill="#E9E9E9" />
                <rect width="80" height="8" rx="4" fill="#FF8A00" />
            </svg>
        } else if (advance === "yellow") {
            return <svg width="200" height="8" viewBox="0 0 200 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="200" height="8" rx="4" fill="#E9E9E9" />
                <rect width="120" height="8" rx="4" fill="#FFC701" />
            </svg>
        } else if (advance === "lightgreen") {
            return <svg width="200" height="8" viewBox="0 0 200 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="200" height="8" rx="4" fill="#E9E9E9" />
                <rect width="160" height="8" rx="4" fill="#B7CD31" />
            </svg>
        } else if (advance === "green") {
            return <svg width="200" height="8" viewBox="0 0 200 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="200" height="8" rx="4" fill="#E9E9E9" />
                <rect width="200" height="8" rx="4" fill="#0ACF83" />
            </svg>
        } else {
            return <svg width="200" height="8" viewBox="0 0 200 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="200" height="8" rx="4" fill="#E9E9E9" />
                <rect width="200" height="8" rx="4" fill="#E9E9E9" /></svg>
        }
    }
    const bar = computeAdvanceBar()
    return (
        <div className='mt-5 mb-5'>{bar}</div>
    )
}
