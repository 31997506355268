import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_PATHS, UrlSearchParams } from "../utils/CONSTANS";
import { useDispatch, useSelector } from "react-redux";
import { selectSiteUrl, setStep } from "../store/stepsSlice";
import { fetchData } from '../store/useCases/fetchData'
export function useThirdStepHook() {
    const siteUrl = useSelector(selectSiteUrl)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const urlFromParams = searchParams.get(UrlSearchParams)

    useEffect(() => {
        const initFetchingData = async () => {
            try {
                const url = siteUrl.length ? siteUrl : urlFromParams
                await dispatch(fetchData({ siteUrl: url, noUseCache: false }))
            } catch (error) {
                throw new Error(error)
            }
        };
        initFetchingData().then();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const reloadAudit = async () => {
        navigate('/')
        const url = siteUrl.length ? siteUrl : urlFromParams
        const fetchDataResponse = await dispatch(fetchData({ siteUrl: url, noUseCache: true }))
        if (fetchData.fulfilled.match(fetchDataResponse)) {
            navigate(`${ROUTE_PATHS.results}?${UrlSearchParams}=${fetchDataResponse.meta.arg.siteUrl}`)
        } else {
            dispatch(setStep(0))
        }
    }

    return { reloadAudit }
}
