import React from 'react'
import SectionCard from './SectionCard'
import { SecurityHook } from './use-Security.hook'
import { nanoid } from 'nanoid'
import SubsectionTitle from './SectionSubComp/SubsectionTitle'
import SubsectionSubtitle from './SectionSubComp/SubsectionSubtitle'
import Paragraph from './SectionSubComp/Paragraph'
import ResultsAnnouncement from './SectionSubComp/ResultsAnnouncement'
import TableWrapper from './SectionSubComp/TableWrapper'
export default function Security({ destination }) {
    const {
        uri_dest,
        secureConnection,
        sortedProtocols,
        sortedVulnerabilitiesBySeverity,
        sortedCiphers,
        sortedResponses,
        testSSL,
        unsafeForms,
        firstSSLScanResult,
        vulnerabilitiesBySeverityCritical,
        vulnerabilitiesBySeverityHigh,
        vulnerabilitiesBySeverityMedium,
        vulnerabilitiesBySeverityLow,
        vulnerabilitiesBySeverityWarn,
        vulnerabilitiesBySeverityInfo
    } = SecurityHook()
    const explainArea = <div>
        <SubsectionTitle value="Chiffrement des données en transit" />
        <SubsectionSubtitle>Activation du protocole HTTPS avec redirection forcée</SubsectionSubtitle>
        <Paragraph value={<span>Les données d'un site internet peuvent être stockées à froid (dans des bases de données) ou peuvent être en transit (depuis le serveur qui l'héberge vers le navicateur de l'internaute vers un autre serveur).
            Ces transits de données se produisent notamment lorsqu'une page est chargée par un navigateur. Pour éviter ce que ces données ne soient interceptées et lues par des personnes n'y étant pas légitimement habilitées, il a été mis en place des standards de chiffrement des données en transit via le protocole HTTP.
            Il s'agit d'une version du protocole HTTP incluant des techniques cryptographiques permettant de s'assurer de la provenance des données (c'est à dire que le serveur est bien celui qui est sensé fournir les données et héberger le site) et que celles-ci ne transitent pas en clair (c'est à dire de manière lisible) jusque leur destination (votre navigateur par exemple).
            Cette version  sécurisée du protocle HTTP est généralement appelée HTTP<span className='font-bold'>S</span>.</span>} />
        <Paragraph value={<span>Tout échange de données entre le navigateur web d'un internaute et le serveur hébergeant un site internet doit donc n'être possible qu'au moyen du protocole HTTPS. Toute requête HTTP étant sujette à compromettre la confidentialité des données ainsi que la provenance de celles-ci.
            Dès lors, il est nécessaire de vérifier que :
            <ul><li>
                - la communication vers le site cible est possible en HTTPS
            </li>
                <li>
                    - la communication vers ce site n'est possible qu'en HTTPS (en redirigeant automatiquement toute requête formulée au moyen de HTTP vers un point de contact du serveur exigeant de dialoguer en HTTPS).
                </li>
            </ul><br />
            Les points de contact sur le serveur d'un site permettant de dialoguer avec lui et étant appelé par les navigateur web lorsqu'une URL leur est soumise sont appelés des <span className='font-bold'>ports</span>.
            Par convention, c'est le port identifié par le numéro 80 permettant de dialoguer en HTTP avec un serveur. Pour le protocole HTTPS il s'agit du port numéro 443. On dit alors qu'il est nécessaire de vérifier que le port 80 soit <span className='font-bold'>redirigé</span> vers le port 443. Si ce n'est pas le cas, alors une faille de sécurité existe car elle permet de laisser perdurer des moyens non confidentiels de dialoguer avec le serveur d'un site internet.</span>} />

        <ResultsAnnouncement>Sur le site <span className='text-greenClara underline'>{uri_dest}</span>, voici les constats effectués par le scanner</ResultsAnnouncement>
        <TableWrapper>
            <table className="use-of-httpsssl divide-gray-300">
                <colgroup>
                    <col width='20%' />
                    <col width='50%' />
                </colgroup>
                <tbody className={'bg-white'}>
                    <tr className={'even:bg-gray-50'}>
                        <td className={'whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-gray-900 sm:pl-3'}>Est-ce que la connexion au serveur en HTTPS est possible ?
                        </td>
                        <td className={'whitespace-nowrap px-3 py-4 text-md font-semibold text-gray-500'}>{secureConnection?.https_support ? "Oui" : "Non"}</td>
                    </tr>
                    <tr className={'even:bg-gray-50'}>
                        <td className={'whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-gray-900 sm:pl-3'}>Est-ce que la redirection forcée HTTP vers HTTPS est mise en oeuvre ?
                        </td>
                        <td className={'whitespace-nowrap px-3 py-4 text-md font-semibold text-gray-500'}>{secureConnection?.https_redirect ? "Oui" : "Non"}</td>
                    </tr>
                    {secureConnection?.redirects && (
                        <tr className={'even:bg-gray-50'}>
                            <td className={'whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-gray-900 sm:pl-3'}>Vers quelles adresses les requêtes ont-elles été redirigées ?
                            </td>
                            <td className={'whitespace-nowrap px-3 py-4 text-md text-greenClara font-semibold'}>
                                <ul>
                                    {secureConnection?.redirects?.map((redirect) => (
                                        <li key={nanoid()}>
                                            <a href={redirect}>{redirect}</a>
                                        </li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    )}
                    {secureConnection?.http_error && (
                        <tr className={'even:bg-gray-50'}>
                            <td className={'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'}>Erreur
                                lors de la connexion avec HTTP
                            </td>
                            <td className={'whitespace-nowrap px-3 py-4 text-sm text-gray-500'}>{secureConnection.http_error}</td>
                        </tr>
                    )}
                    {secureConnection?.https_error && (
                        <tr className={'even:bg-gray-50'}>
                            <td className={'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'}>Erreur
                                lors de la connexion avec HTTPS
                            </td>
                            <td className={'whitespace-nowrap px-3 py-4 text-sm text-gray-500'}>{secureConnection.https_error}</td>
                        </tr>
                    )}
                </tbody>
            </table></TableWrapper>

        <SubsectionSubtitle value="Robustesse du procole de chiffrement"></SubsectionSubtitle>
        <Paragraph value="Pour ajouter une couche de protection au protocole HTTP, plusieurs protocoles additionnels ont été sucessivement développés au cours du temps.
            Certains présentaient intrinsèquement des vulnérabilités, qui ont été corrigées au fur et à mesure des versions successives de ces protocles. Cependant, il arrive que certains protocoles trop anciens et donc vulnérables soient encore employés. Ainsi, le simple fait d'utiliser HTTP<span className='font-bold'>S</span> à lui seul ne constitue pas une garantie suffisante pour s'assurer du chiffrement des données en transit.
            Ce scanner vous permet ci-après de trouver la liste des protocoles qui ont été testés.
            Vous pouvez également prendre connaissance du fait que chaque protcole ait été utilisé ou non et si son emploi présente un quelconque risque ou mérite une investigation de votre part." />
        <ResultsAnnouncement>Voici les résultats pour le site <span className='font-bold text-greenClara'>{uri_dest}</span></ResultsAnnouncement>
        <TableWrapper>
            <table width="100%" className={'divide-gray-300'}>
                <colgroup>
                    <col width={'0%'} />
                    <col width={'100%'} />
                    <col width={'0%'} />
                </colgroup>
                <thead>
                    <tr>
                        <th className={'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'}>Protocole</th>
                        <th className={'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'}>Conclusion</th>
                        <th className={'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'}>Sévérité</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedProtocols?.map((protocol) => (
                        <tr key={protocol.id} className={'even:bg-gray-50'}>
                            <td className="notrunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{protocol.id}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{protocol.finding}</td>
                            <td className="notrunc whitespace-nowrap px-3 py-4 text-sm text-gray-500">{protocol.severity}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </TableWrapper>
        <SubsectionSubtitle>Vulnérabilités du chiffrement HTTPS/SSL</SubsectionSubtitle>
        <Paragraph value={`Il existe des vulnérabilités connues et récurrentes qui affectent les couches de protection du protocole HTTPS.
            Celles-ci ont été testées pour le site ${<span className='font-bold'>{uri_dest}</span>}. Chacune de ces vulnérabilités est répertoriées dans un standard.
            Ainsi, chaque vulnérabilités fait l'objet d'une carte de CVE (Common Vulnerability Exploit).
            Si vous souhaitez en apprendre plus sur chacune d'elles, cliquez sur les liens des CVE.
            Ce sont documents que vous pouvez également transmettre à votre équipe ou prestataire technique afin qu'il procède aux corrections nécessaires si le scanner vous indique le site scanné a été détecté positif à une ou plusieurs failles de sécurité correspondantes.`} />
        <ResultsAnnouncement>Voici les résultats (scroll latéral)</ResultsAnnouncement>
        <TableWrapper>
            <table className={' divide-gray-300'} width={'50%'}>
                <colgroup>
                    <col width={'5%'} />
                    <col width={'5%'} />
                    <col width={'5%'} />
                    <col width={'10%'} />
                </colgroup>
                <thead>
                    <tr>
                        <th className={'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'}>Vulnérabilité</th>
                        <th className={'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'}>Conclusion</th>
                        <th className={'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'}>CVE</th>
                        <th className={'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'}>Sévérité</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedVulnerabilitiesBySeverity?.map((vulnerability) => {
                        return (
                            <tr key={vulnerability.id} className={'even:bg-gray-50'}>
                                <td className="notrunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{vulnerability.id}</td>
                                <td className="trunc whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xl text-ellipsis truncate"
                                    title={vulnerability.finding}>
                                    {vulnerability.finding.trim()}
                                </td>
                                <td className="trunc whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate text-ellipsis max-w-sm">
                                    {vulnerability?.cve?.split(' ').map((cve, cveIndex) => (
                                        <React.Fragment key={nanoid()}>
                                            <a className={'text-billiard'}
                                                href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${cve}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {cve}
                                            </a>
                                            {cveIndex < vulnerability.cve.split(' ').length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                </td>
                                <td className="notrunc whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate text-ellipsis max-w-sm">{vulnerability.severity}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </TableWrapper>

        <SubsectionSubtitle>Détection des faiblesses courantes affectant le chiffrement</SubsectionSubtitle>
        <Paragraph value={`Le scanner détecte la présence (ou non) de certaines faiblesses courantes qui affectent souvent le chiffrement.
            Elles relèvent souvent de défauts de mise à jour, de l'emploi de technologies non maintenues à jour par leurs éditeurs ou encore à des erreurs de programmation.
            Ces faiblesses sont devenues si communes qu'un référentiel (Common Weakness Enumeration, ou "CWE") a été créé pour les répertorier par des indentifiants uniques.
            Certaines de ces faiblesses pouvant avoir des origines différentes, les différentes causes ont été également standardisées et sont répertoriées dans un référentiel "cipherlist_".`} />

        <ResultsAnnouncement>Voici les résultats pour le site <span className='font-semibold text-green-400'>{uri_dest}</span></ResultsAnnouncement>
        <TableWrapper>
            <table width="100%" className={'divide-gray-300'}>
                <colgroup>
                    <col width={'0%'} />
                    <col width={'100%'} />
                    <col width={'0%'} />
                    <col width={'0%'} />
                </colgroup>
                <thead>
                    <tr>
                        <th className={'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'}>Nom</th>
                        <th className={'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'}>Conclusion</th>
                        <th className={'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'}>CWE</th>
                        <th className={'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'}>Sévérité</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedCiphers?.map((cipher) => (
                        <tr key={cipher.id} className={'even:bg-gray-50'}>
                            <td className="notrunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{cipher.id}</td>
                            <td className="notrunc whitespace-nowrap px-3 py-4 text-sm text-gray-500">{cipher.finding}</td>
                            <td className="notrunc whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {cipher.cwe && (
                                    <a
                                        href={`https://cwe.mitre.org/cgi-bin/jumpmenu.cgi?id=${cipher.cwe.replace('CWE-', '')}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {cipher.cwe}
                                    </a>
                                )}
                            </td>
                            <td className="notrunc whitespace-nowrap px-3 py-4 text-sm text-gray-500">{cipher.severity}</td>
                        </tr>
                    ))}
                </tbody>
            </table></TableWrapper>
        <SubsectionSubtitle>Configurations des politiques de sécurité du site internet</SubsectionSubtitle>
        <Paragraph value="Le scanner effectue une analyse détaillée de la sécurité et de la configuration du site web scanné.
            Chaque ligne du tableau ci-après représente une caractéristique spécifique évaluée lors du scan, avec des informations telles que la conclusion sur la conformité aux bonnes pratiques de sécurité,
            ainsi que la sévérité de tout écart éventuel. Les différentes métriques incluent des éléments tels que l'utilisation de HSTS (HTTP Strict Transport Security), la gestion des cookies,
            les codes de statut HTTP, les directives de cache, les informations sur les serveurs et les applications, entre autres.
            Ces résultats peuvent être transmis aux administrateurs système et aux professionnels de la sécurité informatique de mieux comprendre la posture de sécurité de leur site web et d'identifier les éventuels points faibles ou les domaines nécessitant une amélioration." />
        <ResultsAnnouncement>Voici les résultats pour le site <span className='font-bold text-greenClara'>{uri_dest}</span></ResultsAnnouncement>
        <TableWrapper>
            <table width="100%" className={'divide-gray-300'}>
                <colgroup>
                    <col width={'0%'} />
                    <col width={'100%'} />
                    <col width={'0%'} />
                </colgroup>
                <thead>
                    <tr>
                        <th className={'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'}>Nom</th>
                        <th className={'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'}>Conclusion</th>
                        <th className={'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'}>Sévérité</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedResponses?.map((response) => (
                        <tr key={response.id} className={'even:bg-gray-50'}>
                            <td className="notrunc whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{response.id}</td>
                            <td className="trunc whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-md text-ellipsis truncate" title={response.finding}>
                                {response.finding}
                            </td>
                            <td className="notrunc whitespace-nowrap px-3 py-4 text-sm text-gray-500">{response.severity}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </TableWrapper>
        <SubsectionSubtitle>Sécurité des envoie de données de formulaires</SubsectionSubtitle>
        <Paragraph value="Le scanner teste la présence de formulaires présentant des failles de sécurité dans les envois de données qu'ils permettent de recueillir.
            Lorsque vous saisissez et soumettez des données au travers d'un formulaire, un envoi de ces données est effectué selon différentes méthode HTTP (POST, GET, etc.).
            En fonction de cela et d'autres paramètres de configuration relatifs à la manière dont le formulaire est supposé envoyer ces informations à leur destination, il est possible de constater des failles de sécurité et surtout de confidentialité.
            Certains sites peuvent notamment recourir au chiffrement lorsqu'ils sont chargés par des internautes, mais les requêtes d'envoi des données peuvent quant à elles avoir été ommises d'être chiffrées.
            Ce scanner vous aide à détecter si tel est le cas pour le site scanné." />
        <ResultsAnnouncement>Voici les résultats pour le site <span className='font-bold text-greenClara'>{uri_dest}</span> :</ResultsAnnouncement>

        {unsafeForms?.length > 0 ? (
            <TableWrapper>
                <table className="unfase-webforms divide-gray-300">
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">#</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">ID du
                                Formulaire Web
                            </th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">URL du
                                Destinataire
                            </th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Méthode
                                HTTP
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {unsafeForms.map((form, index) => (
                            <tr key={nanoid()} className="even:bg-gray-50">
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{index + 1}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{form.id}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{form.action}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{form.method}</td>
                            </tr>
                        ))}
                    </tbody>
                </table></TableWrapper>) : (<Paragraph value="Aucun formulaire web soumettant des données sans chiffrement
                    SSL n'a été détecté." />)}
    </div>

    const actionsArea = <div>
        <SubsectionTitle value="Récapitulatif du nombre de vulnérabilités" />

        {testSSL && firstSSLScanResult && (
            <TableWrapper>
                <table class="table-auto divide-y divide-gray-200" width={'10vw'}>
                    <thead>
                        <tr>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Niveau de gravité
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Nombre de vulnérabilités
                            </th>
                            <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Signification
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        <tr className={'even:bg-gray-50'}>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Critique</td>
                            <td className={'whitespace-nowrappy-4 py-4 px-6 text-center text-sm font-medium text-gray-900 sm:pl-3'}>{vulnerabilitiesBySeverityCritical}</td>
                            <td className="whitespace-nowrappy-4 py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3">Il s'agit des vulnérabilités les plus graves qui peuvent avoir un impact majeur sur la sécurité du système. Elles doivent être corrigées immédiatement pour éviter toute exploitation.</td>
                        </tr>
                        <tr className={'even:bg-gray-50'}>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Élevée</td>
                            <td className={'whitespace-nowrap py-4 px-6 text-center text-sm font-medium text-gray-900 sm:pl-3'}>{vulnerabilitiesBySeverityHigh}</td>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Ces éléments représentent des vulnérabilités sérieuses qui peuvent être exploitées pour compromettre la sécurité du serveur ou des données. Ils doivent être traités avec la plus haute priorité.</td>
                        </tr>
                        <tr className={'even:bg-gray-50'}>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Moyenne</td>
                            <td className={'whitespace-nowrap py-4 px-6 text-center text-sm font-medium text-gray-900 sm:pl-3'}>{vulnerabilitiesBySeverityMedium}</td>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Les éléments classés comme moyens indiquent des vulnérabilités ou des faiblesses qui peuvent être exploitées dans certaines conditions. Bien qu'ils ne représentent pas une menace immédiate, ils devraient être corrigés dès que possible.	</td>
                        </tr>
                        <tr className={'even:bg-gray-50'}>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Faible</td>
                            <td className={'whitespace-nowrap py-4 px-6 text-center text-sm font-medium text-gray-900 sm:pl-3'}>{vulnerabilitiesBySeverityLow}</td>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Ces éléments représentent des problèmes de sécurité mineurs qui pourraient être exploités dans certaines circonstances mais qui ne sont pas considérés comme graves.</td>
                        </tr>
                        <tr className={'even:bg-gray-50'}>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Améliorations nécessaires</td>
                            <td className={'whitespace-nowrap py-4 px-6 text-center text-sm font-medium text-gray-900 sm:pl-3'}>{vulnerabilitiesBySeverityWarn}</td>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Il s'agit d'un avertissement indiquant un problème potentiel ou une configuration qui pourrait être améliorée pour renforcer la sécurité. Bien que ce ne soit pas une vulnérabilité critique, il est recommandé de prendre des mesures correctives.</td>
                        </tr>
                        <tr className={'even:bg-gray-50'}>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Défauts de configuration</td>
                            <td className={'whitespace-nowrap py-4 px-6 text-center text-sm font-medium text-gray-900 sm:pl-3'}>{vulnerabilitiesBySeverityInfo}</td>
                            <td className={'whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 sm:pl-3'}>Ces éléments fournissent des informations utiles mais non critiques sur la configuration du serveur. Ils n'indiquent généralement pas de problème de sécurité direct, mais peuvent être intéressants pour mieux comprendre la configuration.</td>
                        </tr>
                    </tbody>
                </table>
            </TableWrapper>)}


    </div>
    return (
        <SectionCard title="Sécurité du site internet" explainations={explainArea} actions={actionsArea} destination={destination}>

        </SectionCard>
    )
}
