import { createSlice } from '@reduxjs/toolkit'
import { fetchData } from "./useCases/fetchData";
import { initialAuditData } from "../initialAuditData";

const initialState = {
    step: 0,
    siteUrl: '',
    status: '',
    loading: false,
    auditData: initialAuditData
}


const stepsSlice = createSlice({
    name: 'steps',
    initialState,
    reducers: {
        setStep: (state, action) => {
            state.step = action.payload
        },
        setSiteUrl: (state, action) => {
            state.siteUrl = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setAuditData: (state) => {
            state.auditData = initialAuditData
        }
    },
    extraReducers: (build) => {
        build.addCase(fetchData.pending, (state, action) => {
            state.loading = true
            state.step = 1
        })
        build.addCase(fetchData.fulfilled, (state, action) => {
            state.loading = false
            state.auditData = action.payload
        })
        build.addCase(fetchData.rejected, (state, action) => {
            state.loading = false
        })
    }
})

export const { setStep, setSiteUrl, setStatus, setAuditData } = stepsSlice.actions
export default stepsSlice.reducer

export const selectCurrentStep = (state) => {
    return state.entities.steps.step
}

export const selectSiteUrl = (state) => {
    return state.entities.steps.siteUrl
}

export const selectStatus = (state) => {
    return state.entities.steps.status
}

export const selectLoading = (state) => {
    return state.entities.steps.loading
}

export const selectAuditData = (state) => {
    return state.entities.steps.auditData
}

