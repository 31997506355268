import { useSelector } from "react-redux";
import { selectAuditData } from "../../store/stepsSlice";

export function useRecipientsAndTransfersHook() {
    const auditData = useSelector(selectAuditData)

    const uri_dest = auditData?.data?.uri_dest
    const browsing_history = auditData?.data?.browsing_history
    const start_time = auditData?.data?.start_time
    const end_time = auditData?.data?.end_time
    const browser = auditData?.data?.browser
    const uri_refs = auditData?.data?.uri_refs
    const hosts = auditData?.data?.hosts
    const links = auditData?.data?.links

    return { uri_dest, browsing_history, start_time, end_time, browser, uri_refs, hosts, links }
}