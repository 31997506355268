import React from 'react'
import logo from '../assets/img/olympe-logo.png'

export default function OlympeFullLogo(props) {
    // return (
    //     <svg {...props} width="140" height="53" xmlns="http://www.w3.org/2000/svg">
    //         <g clipPath="url(#clip0_558_5135)">
    //             <path
    //                 d="M21.1642 42.4053C23.1877 37.9863 27.0431 34.6547 31.7326 33.3583C34.7678 32.4661 39.3478 32.8285 42.2326 32.48C47.9201 31.6436 51.967 24.9803 51.4474 19.9619C51.4338 19.8085 51.2287 19.7667 51.1603 19.8922L50.5451 20.9516V20.9656C47.551 26.221 37.2013 24.1857 31.049 25.4961C25.3205 26.904 21.383 31.7412 20.59 36.8711C20.4123 37.7912 20.3029 38.6833 20.2619 39.5058C20.0978 42.0987 20.3713 43.7296 20.3713 43.7296C20.426 43.9527 20.5217 43.9248 20.6037 43.7018C20.7267 43.3672 20.8635 43.0326 21.0138 42.712L21.1642 42.4053Z" />
    //             <path
    //                 d="M45.8965 33.9578C45.8965 33.7905 45.7051 33.7068 45.5821 33.8184C43.2031 35.7839 39.0469 35.6724 39.0469 35.6724C28.1231 34.9057 24.4864 42.029 23.8438 43.5485C23.7891 43.674 23.8848 43.8273 24.0215 43.8273C24.0215 43.8273 30.4336 40.7187 36.668 40.5096C42.9024 40.2865 45.8965 33.9717 45.8965 33.9578Z" />
    //             <path
    //                 d="M39.9356 22.4014C43.5997 21.5372 50.4356 21.7741 49.0958 16.3794C49.0684 16.24 48.8907 16.226 48.8223 16.3375C46.5665 20.2965 42.629 16.8951 39.8262 22.2342C39.7852 22.3317 39.8536 22.4154 39.9356 22.4014Z" />
    //             <path
    //                 d="M38.377 22.318C36.7773 13.731 29.3945 7.48584 20.8223 7.48584C10.9648 7.48584 2.9668 15.6547 2.9668 25.6915C2.9668 34.8362 9.66602 42.6008 18.5391 43.7578L19.1406 43.8414L19.0586 43.2281C18.9629 42.5729 18.7578 40.8304 18.9082 38.6L18.9355 38.1957L18.5391 38.1121C12.6875 36.9829 8.44922 31.7694 8.44922 25.6915C8.44922 18.7215 14.0137 13.0479 20.8496 13.0479C26.5371 13.0479 31.4863 16.979 32.8672 22.6108L32.9766 23.029L33.3867 22.9593C33.5508 22.9314 34.4668 22.8338 35.6426 22.7641L38.459 22.6247L38.377 22.318Z" />
    //             <path
    //                 d="M80.8965 43.8967C80.7051 43.8967 80.5547 43.827 80.418 43.6876C80.2812 43.5482 80.2129 43.3949 80.2129 43.1997C80.2129 43.0046 80.2812 42.8373 80.418 42.6979C80.5547 42.5585 80.7188 42.4888 80.8965 42.4888C81.0742 42.4888 81.2519 42.5585 81.3887 42.6979C81.5254 42.8373 81.5937 43.0046 81.5937 43.1997C81.5937 43.3949 81.5254 43.5482 81.3887 43.6876C81.2519 43.827 81.0742 43.8967 80.8965 43.8967Z" />
    //             <path
    //                 d="M83.1113 43.7573V36.8013H84.2461V43.7573H83.1113ZM83.9043 43.7573V42.7118H87.3359V43.7573H83.9043Z" />
    //             <path
    //                 d="M88.5664 43.7573V36.8013H89.7012V43.7573H88.5664ZM89.4551 37.8468V36.8013H92.7637V37.8468H89.4551ZM89.4551 40.7324V39.7008H92.3945V40.7324H89.4551ZM89.4551 43.7573V42.7118H92.791V43.7573H89.4551Z" />
    //             <path
    //                 d="M97.7129 43.8691C97.0293 43.8691 96.4141 43.7157 95.8809 43.409C95.3477 43.1024 94.9238 42.6842 94.623 42.1405C94.3223 41.5968 94.1719 40.9835 94.1719 40.2865C94.1719 39.5895 94.3223 38.9482 94.623 38.4185C94.9238 37.8888 95.3477 37.4566 95.8809 37.15C96.4141 36.8433 97.0293 36.6899 97.7129 36.6899C98.1094 36.6899 98.4785 36.7596 98.834 36.8851C99.1895 37.0106 99.4902 37.1778 99.7773 37.4009C100.051 37.61 100.283 37.8609 100.461 38.1536L99.5449 38.7531C99.4219 38.5579 99.2578 38.3906 99.0664 38.2373C98.875 38.0839 98.6563 37.9724 98.4238 37.8888C98.1914 37.8051 97.959 37.7633 97.7129 37.7633C97.2481 37.7633 96.8242 37.8748 96.4687 38.0979C96.1133 38.3209 95.8262 38.6137 95.6211 39.004C95.416 39.3804 95.3066 39.8264 95.3066 40.3283C95.3066 40.8301 95.416 41.2483 95.6074 41.6387C95.8125 42.029 96.0996 42.3217 96.4687 42.5448C96.8379 42.7678 97.2617 42.8793 97.7402 42.8793C98.1231 42.8793 98.4512 42.7957 98.752 42.6423C99.0527 42.489 99.2852 42.266 99.4629 41.9732C99.6406 41.6944 99.7227 41.3599 99.7227 40.9974L100.803 40.858C100.803 41.4853 100.666 42.029 100.406 42.489C100.146 42.949 99.7773 43.2975 99.3125 43.5484C98.8477 43.7436 98.3145 43.8691 97.7129 43.8691ZM98.082 41.0392V40.1052H100.803V40.8859L100.201 41.0392H98.082Z" />
    //             <path
    //                 d="M101.459 43.7573L104.18 36.8013H105.314L102.648 43.7573H101.459ZM102.73 42.0427V41.0251H106.805V42.0427H102.73ZM106.9 43.7573L104.234 36.8013H105.369L108.104 43.7573H106.9Z" />
    //             <path
    //                 d="M109.266 43.7573V36.8013H110.4V43.7573H109.266ZM110.059 43.7573V42.7118H113.49V43.7573H110.059Z" />
    //             <path
    //                 d="M66.418 28.5629C64.9277 28.5629 63.6152 28.2284 62.4805 27.5592C61.332 26.8901 60.4434 25.9561 59.7871 24.7573C59.1309 23.5585 58.8164 22.1923 58.8164 20.645C58.8164 19.0976 59.1445 17.7315 59.7871 16.5466C60.4434 15.3617 61.332 14.4277 62.4531 13.7447C63.5742 13.0616 64.8867 12.7271 66.3496 12.7271C67.8125 12.7271 69.1523 13.0616 70.2871 13.7447C71.4219 14.4277 72.3105 15.3617 72.9395 16.5466C73.582 17.7315 73.8965 19.0976 73.8965 20.645C73.8965 22.1923 73.582 23.5585 72.9395 24.7573C72.2969 25.9561 71.4219 26.8901 70.3008 27.5592C69.1797 28.2284 67.8809 28.5629 66.418 28.5629ZM66.418 26.2489C67.3887 26.2489 68.25 26.0119 68.9883 25.524C69.7266 25.05 70.3145 24.3809 70.7383 23.5445C71.1621 22.7081 71.3672 21.7463 71.3672 20.645C71.3672 19.5437 71.1484 18.6097 70.7246 17.7594C70.3008 16.9091 69.7129 16.2539 68.9609 15.7799C68.209 15.306 67.3477 15.069 66.3633 15.069C65.3789 15.069 64.5312 15.306 63.7793 15.7799C63.0273 16.2539 62.4395 16.9091 62.002 17.7455C61.5645 18.5819 61.3457 19.5437 61.3457 20.645C61.3457 21.7463 61.5645 22.7081 62.002 23.5445C62.4395 24.3809 63.041 25.0361 63.8066 25.524C64.5586 26.0119 65.4336 26.2489 66.418 26.2489Z" />
    //             <path d="M76.084 28.2981V13.0059H78.4766V28.2981H76.084Z" />
    //             <path
    //                 d="M84.7383 28.2981L79.9395 17.3691H82.5508L86.2832 26.5696L87.0488 28.2981H84.7383ZM82.8242 32.9402C82.4824 32.9402 82.0996 32.8705 81.6758 32.745C81.252 32.6195 80.8555 32.4523 80.459 32.2432L81.3613 30.2497C81.6348 30.3752 81.8809 30.4867 82.1133 30.5704C82.3457 30.654 82.5234 30.6958 82.6738 30.6958C83.0156 30.6958 83.3027 30.6122 83.5352 30.417C83.7676 30.2358 83.959 29.9709 84.1094 29.6224L85.3672 26.6114L88.9902 17.397H91.6016L86.625 29.483C86.3242 30.18 86.0234 30.7934 85.709 31.3231C85.4082 31.8528 85.0254 32.2571 84.5605 32.5359C84.123 32.8008 83.5488 32.9402 82.8242 32.9402Z" />
    //             <path
    //                 d="M93.0508 28.2983V17.3693H95.293L95.4434 19.3767V28.2983H93.0508ZM100.311 22.7083C100.311 21.8301 100.215 21.161 100.023 20.687C99.832 20.2131 99.5586 19.8785 99.2031 19.6973C98.8477 19.5161 98.4375 19.4185 97.9727 19.4185C97.1797 19.4045 96.5508 19.6694 96.1133 20.227C95.6758 20.7846 95.4434 21.5792 95.4434 22.6108H94.541C94.541 21.4677 94.7051 20.4779 95.0332 19.6555C95.3613 18.833 95.8262 18.2057 96.4414 17.7736C97.0566 17.3414 97.7813 17.1323 98.6289 17.1323C99.9277 17.1323 100.926 17.5366 101.637 18.3451C102.348 19.1536 102.703 20.3943 102.703 22.0671L100.311 22.7083ZM100.311 28.2983V22.7083L102.703 22.0532V28.2983H100.311ZM107.598 22.7083C107.598 21.8301 107.502 21.161 107.297 20.687C107.092 20.2131 106.818 19.8785 106.477 19.6973C106.121 19.5021 105.725 19.4185 105.26 19.4185C104.439 19.4045 103.824 19.6694 103.373 20.227C102.922 20.7846 102.703 21.5792 102.703 22.6108H101.801C101.801 21.4677 101.965 20.4779 102.293 19.6555C102.621 18.833 103.086 18.2057 103.701 17.7736C104.316 17.3414 105.041 17.1323 105.889 17.1323C107.188 17.1323 108.199 17.5366 108.91 18.3451C109.621 19.1536 109.977 20.3943 109.963 22.0671L107.598 22.7083ZM107.598 28.2983V22.7083L109.977 22.0532V28.2983H107.598Z" />
    //             <path
    //                 d="M112.52 32.6752V17.383H114.775L114.926 19.3904V32.6752H112.52ZM118.316 28.5629C117.414 28.5629 116.648 28.326 115.992 27.852C115.336 27.378 114.844 26.7089 114.502 25.8586C114.16 24.9943 113.982 23.9906 113.982 22.8475C113.982 21.7044 114.146 20.6729 114.502 19.8225C114.857 18.9722 115.336 18.3031 115.992 17.8291C116.648 17.3551 117.414 17.1182 118.316 17.1182C119.287 17.1182 120.135 17.3551 120.859 17.8291C121.584 18.3031 122.145 18.9582 122.555 19.8225C122.965 20.6868 123.17 21.6905 123.17 22.8475C123.17 24.0045 122.965 25.0082 122.555 25.8586C122.145 26.7089 121.584 27.378 120.859 27.852C120.121 28.326 119.287 28.5629 118.316 28.5629ZM117.715 26.3325C118.289 26.3325 118.795 26.1792 119.232 25.8864C119.67 25.5937 120.025 25.1755 120.299 24.6458C120.559 24.1161 120.695 23.5166 120.695 22.8336C120.695 22.1505 120.572 21.5511 120.326 21.0214C120.08 20.4917 119.725 20.0874 119.287 19.7947C118.85 19.5019 118.33 19.3625 117.742 19.3625C117.195 19.3625 116.717 19.5158 116.279 19.7947C115.855 20.0874 115.514 20.4917 115.281 21.0214C115.049 21.5511 114.926 22.1505 114.926 22.8336C114.926 23.5166 115.049 24.1161 115.281 24.6458C115.514 25.1755 115.842 25.5798 116.266 25.8864C116.689 26.1931 117.168 26.3325 117.715 26.3325Z" />
    //             <path
    //                 d="M129.992 28.5631C128.953 28.5631 128.023 28.3122 127.217 27.8383C126.41 27.3504 125.781 26.6812 125.303 25.817C124.838 24.9527 124.605 23.9629 124.605 22.8477C124.605 21.7325 124.838 20.7428 125.316 19.8785C125.795 19.0142 126.437 18.3451 127.258 17.8572C128.078 17.3693 129.021 17.1323 130.074 17.1323C131.031 17.1323 131.893 17.3832 132.658 17.8851C133.424 18.3869 134.039 19.1118 134.49 20.0597C134.941 21.0077 135.174 22.1368 135.174 23.4472H132.754C132.754 22.4992 132.645 21.7186 132.426 21.1052C132.207 20.4919 131.893 20.0458 131.482 19.7391C131.072 19.4464 130.58 19.293 129.992 19.293C129.377 19.293 128.844 19.4324 128.406 19.7112C127.969 19.99 127.627 20.3943 127.395 20.924C127.162 21.4537 127.053 22.1229 127.053 22.9174C127.053 23.6284 127.189 24.2417 127.477 24.7575C127.764 25.2733 128.133 25.6497 128.611 25.9285C129.09 26.1933 129.623 26.3327 130.211 26.3327C130.854 26.3327 131.387 26.1794 131.797 25.8867C132.207 25.5939 132.535 25.2036 132.754 24.7436L134.955 25.7054C134.654 26.2909 134.271 26.7928 133.807 27.2388C133.342 27.671 132.795 28.0055 132.152 28.2425C131.523 28.4516 130.799 28.5631 129.992 28.5631ZM126.191 23.4332V21.4956H133.902V23.4332H126.191Z" />
    //         </g>
    //         <defs>
    //             <clipPath id="clip0_558_5135">
    //                 <rect width="280" height="106" fill="white" />
    //             </clipPath>
    //         </defs>
    //     </svg>
    // )
    return (
        <div className='flex center w-full p-1 mb-5'>
            <img src={logo} alt="Olympe.legal" />
        </div>
    )
}
