import { useSelector } from "react-redux";
import { selectAuditData } from "../../store/stepsSlice";



function isBad(protocol) {
    if (protocol.severity === "LOW") {
        return true
    } else if (protocol.severity === "MEDIUM") {
        return true
    } else if (protocol.severity === "HIGH") {
        return true
    } else if (protocol.severity === "CRITICAL") {
        return true
    } else {
        return false
    }
}

function browseLocalStorage(localStorage) {
    let dataInCache = []
    Object.keys(localStorage).map(domain =>
        Object.keys(localStorage[domain]).map(key => dataInCache.push(localStorage[domain][key]))
    );
    return dataInCache
}

function computeDataScore(cookies, beacons, localStorage) {
    let score = 5

    // on compte le nombre de traceurs 
    // s'il y en a (-1pt)
    const beaconsNumber = beacons.length
    if (beaconsNumber > 0) {
        score = score - 1
    }


    // on compte le nombre de cookies qui ne sont pas de session et on regarde si vie > 13 mois
    // si cookies non session (-1pt)
    const nonSessionCookies = cookies.filter(cookie => cookie.session === false)
    if (nonSessionCookies.length > 0) {
        score = score - 1
    }
    // si cookies > 13 mois (-1pt)
    const badCookies = nonSessionCookies.filter(cookie => cookie.expiresDays === 395.417)
    if (badCookies.length > 0) {
        score = score - 1
    }

    // on regarde s'il y a des données persistentes en cache et regarde s'il y en a beaucoup (<4)
    // si oui (-1pt)
    const dataInCache = browseLocalStorage(localStorage)
    if (dataInCache.length > 0) {
        score = score - 1
    }


    return score
}

function computeTransfersScore(hosts, social) {
    let score = 5
    let thirdPartyList = []
    // est-ce qu'il y a des requêtes hors du même nom de domaine
    // est-ce qu'il y a des requêtes en www.memenom.domain
    // est-ce qu'il y en a d'autre ?
    // si oui (-1pt)
    if (hosts.requests.thirdParty.length > 0) {
        thirdPartyList = hosts.requests.thirdParty.filter(host => host !== "www." + hosts.requests.firstParty[0])
    }

    if (thirdPartyList.length > 0) {
        score = score - 1
    }
    // Est-ce qu'il y a utilisation des réseaux sociaux ?
    // si oui (-1pt)
    if (social.length > 0) {
        score = score - 1
    }

    return score
}

function computeSecurityScore(httpsSupport, httpsRedirect, protocols, cve, serverConfig, ciphers, unsafeForms) {
    let score = 5
    // est-ce que HTTPs est activé ? 
    // si non (-0.5pt)
    if (httpsSupport === false) {
        score = score - 0.5
    }

    // est-ce que HTTPs est forcé ?
    // si non (-0.5pt)
    if (httpsRedirect === false) {
        score = score - 0.5
    }

    // est-ce qu'il y a une vulnérabilité au moins faible quant au protocole ?
    // si oui (-0.5pt)
    const badProtocols = protocols.filter(protocol => isBad(protocol))
    if (badProtocols.length > 0) {
        score = score - 0.5
    }

    // est-ce qu'il y a des CVE >= faible sur HTTPS ?
    // si oui (-1pt)
    const cveProblems = cve.filter(vulnerability => isBad(vulnerability))
    if (cveProblems.length > 0) {
        score = score - 1
    }

    // est-ce qu'il ya des faiblesse CWE cipherlist >= faible ?
    // si oui (-1pt)
    const cweCiphersProblems = ciphers.filter(cwe => isBad(cwe))
    if (cweCiphersProblems.length) {
        score = score - 1
    }
    // est-ce qu'il y a des soucis de config >= faible ?
    // si oui (-0.5pt)
    const serverConfigProblems = serverConfig.filter(config => isBad(config))
    if (serverConfigProblems.length > 0) {
        score = score - 0.5
    }

    // est-ce qu'il y a des formulaire sans chiffrement ?
    // si oui (-1pt)
    if (unsafeForms.length > 0) {
        score = score - 0.5
    }


    return score
}

function computeGlobalScore(dataScore, transfersScore, securityScore) {
    return Math.round((dataScore + transfersScore + securityScore) * 6.67)
}

export function useHeaderHook() {
    const auditData = useSelector(selectAuditData)

    const beacons = auditData?.data?.beacons || []
    const cookies = auditData?.data?.cookies || []
    const localStorage = auditData?.data?.localStorage || []
    const hosts = auditData?.data?.hosts || []
    const social = auditData?.data?.social || []
    const httpsSupport = auditData?.data?.secure_connection?.https_support || []
    const httpsRedirect = auditData?.data?.secure_connection?.https_redirect || []
    const protocols = auditData?.data?.protocols || []
    const cve = auditData?.data?.testSSL?.scanResult[0]?.vulnerabilities || []
    const serverConfig = auditData?.data?.testSSL?.scanResult[0]?.serverPreferences || []
    const ciphers = auditData?.data?.testSSL?.scanResult[0]?.ciphers || []
    const unsafeForms = auditData?.data?.unsafeForms || []

    const dataScore = computeDataScore(cookies, beacons, localStorage)
    const transfersScore = computeTransfersScore(hosts, social)
    const securityScore = computeSecurityScore(httpsSupport, httpsRedirect, protocols, cve, serverConfig, ciphers, unsafeForms)

    const globalScore = computeGlobalScore(dataScore, transfersScore, securityScore)
    return {
        dataScore,
        transfersScore,
        securityScore,
        globalScore
    }
}