export default function SecondStep() {
    return (
        <div className='text-center w-1/2'>
            <h1 className='tracking-tight text-4xl text-primary font-bold mb-5'>Détectez les risques RGPD d'un site internet</h1>
            <div className="mx-auto mb-5 border-t-4 border-slate-800 border-solid rounded-full h-12 w-12 animate-spin text-black"></div>
            <p className={'mb-4 text-xl font-bold leading-6 text-primary'}>Analyse du site en cours</p>
            <p className={'mb-4 mt-10 text-4xl leading-1 text-primary'}>☕️</p>
            <p className={'mb-4 text-base leading-6 text-primary'}>Cela peut prendre plusieurs minutes, n'hésitez pas à laisser cet onglet ouvert et à y revenir d'ici quelques instants.</p>
            <p className={'mb-4 text-base leading-6 text-primary'}>Le scanner détecte pour vous : les cookies déposés(et leurs durée de vie), de potentiels transferts de données hors unions, des pixels cachés, des failles de sécurité dans le chiffrement (HTTPS, SSL, données de formulaire en transit). D'autres points d'audit arrivent prochainement.</p>
            <p className={'mb-4 text-base leading-6 text-primary'}>Pour patienter, vous pouvez effectuer des <span className='font-bold'>recherches juridiques</span> et <span className='font-bold'>analyser des documents</span> en vous créant un compte sur <a className="font-bold text-billiard underline" target='_blank' href="https://olympe.legal" rel="noreferrer">Olympe.legal</a></p>
            <a target="_blank" href="https://www.dpo-gpt.fr/register" rel="noreferrer"><button
                type="submit"
                className=" hover:cursor-pointer inline-flex items-center gap-x-2 rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
            >
                Créer mon compte Olympe
            </button></a>
        </div >
    )
}
