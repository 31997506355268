import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchData = createAsyncThunk(
    'steps/fetchData',
    async ({ siteUrl, noUseCache }, { extra: { scanGateway } }) => {
        try {
            return await scanGateway.fetchAuditData(siteUrl, noUseCache)
        } catch (error) {
            throw new Error((error))
        }
    }
)
